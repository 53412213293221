import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import taiji from '../../images/taiji.gif'

export default function YinYang() {
  return (
    <React.Fragment>
      <Typography  variant="h5"  align="left" >
        Yin and Yang
      </Typography>
      <Typography paragraph align="left">
        Understanding the concept of Yin and Yang is crucial to understanding the tenets of
        traditional Chinese medicine. All disorders, at their most basic, can be broken down to show
        an imbalance of Yin and Yang, and all our treatments are fundamentally efforts to regain
        their equilibrium. The concept, while extremely simple, is yet quite profound. Yin/Yang
        theory starts with the notion that all things in the universe can be divided into a Yin
        portion and a Yang portion. It continues to state that each of those portions is itself
        infinitely divisible into smaller and smaller partitions of Yin and Yang. There is nothing
        that is entirely Yin in character nor anything that is entirely Yang; they only exist in
        relation to one another. The most easily understood example of this is the
        no-darkness-without-light scenario. (Quantum physicists just love this sort of thing.)
      </Typography>
      <Typography paragraph align="left">
        Yin and Yang are represented graphically by the <i>tai ji</i> symbol, shown below. In it,
        two separate but equal halves&#8212; black for Yin, white for Yang&#8212;swirl around each
        other and transform into one another, with each containing a kernel of the other at heart.
        TCM theory states that Yin and Yang are inseparable in life&#8212;we cannot have night
        without day, cold without hot, movement without stillness, etc&#8212;and when each remains
        in balance with the other, harmony prevails and we remain healthy. In human terms, Yin and
        Yang separate only at the moment we die, and even then the universal balance is maintained.
        All things remain in flux, yet and all things remain the same. (The theory of Yin and Yang
        applies to much more than just our health, but for the sake of this forum, we'll focus on
        its medicinal applications.)
      </Typography>
      <Grid
  container
  direction="row"
  justify="center"
  alignItems="flex-start"
>
  <div>
  <span className="header2">Yin characteristics</span>
              <ul className="noBullets">
                <li>Interior</li>
                <li>Downward</li>
                <li>Sinking</li>
                <li>Water</li>
                <li>Cool</li>
                <li>Calm</li>
                <li>Female</li>
                <li>Nighttime</li>
                <li>Winter</li>
                <li>Soft</li>
              </ul>
  </div>
  <img src={taiji} style={{marginTop:'50px'}} alt="Yin and Yang" border="0" width="100" height="100" />
  <div>
              <span className="header2">Yang characteristics</span>
              <ul className="noBullets">
                <li>Exterior</li>
                <li>Upward</li>
                <li>Floating</li>
                <li>Fire</li>
                <li>Warm</li>
                <li>Excited</li>
                <li>Male</li>
                <li>Daytime</li>
                <li>Summer</li>
                <li>Hard</li>
              </ul>
              </div>    

</Grid>
        
      <Typography  variant="h5"  align="left" >
        Yin and Yang in Relation to One Another
      </Typography>
      <Typography paragraph align="left">
        The upper half of the body is Yang compared to the lower half. The back of the body is Yang
        compared to the front. The scalp is Yang compared to the face. The extremities are Yang
        compared to the torso. You get an idea of the infinite divisibility of each body into Yin
        and Yang aspects. This is also true in nature. For example, water is Yin compared to fire
        but Yang compared to mountains. The difference is in how we look at them. Water is cool
        while fire is hot, but water flows while mountains are still.
      </Typography>
      <Typography paragraph align="left">
        These analogies are also seen in the homeostatic functioning of our bodies. Fire represents
        the flame that keeps us alive and stokes all our metabolic processes. Water in turn
        represents the cooling, nourishing aspects of our systems. Kidney Fire (in the form of Yang
        Qi) warms the Spleen, which in turn is able to transform our food into useable Food Qi.
        (Without that Yang Fire, our digestive system would be like using a rice-cooker that's not
        plugged in; you can wait all day, but the hard rice you have in the pot isn't going to
        cook.) The Lungs receive the "mist" from the cook-pot Spleen and in turn rain that mist down
        to the Kidneys, which are thus kept cool (tempering the Yang Fire). Likewise, the Fire of
        the Heart assists in our mental and emotional functioning, but, lest we get too "hot
        headed," our Kidney Yin keeps the Heart Fire in check. Yin and Yang thus maintain harmony
        and our bodies remain healthy.
      </Typography>
      <Typography  variant="h5"  align="left" >
        Yin and Yang in TCM Treatment
      </Typography>
      <Typography paragraph align="left">
        Yin and Yang become imbalanced in a variety of ways, sometimes with a <i>true</i>
        excess or deficiency of one over to the other, other times with a <i>relative</i>
        excess or deficiency. One example of a <i>true excess </i> of Yang over Yin is a raging
        fever (redness and heat belong to Yang) from an acute flu or infection in an otherwise
        healthy person. The person's store of nourishing, cooling Yin fluids has not yet been
        damaged by the high fever, which has just begun; thus, the overall state of the Yin remains
        unchanged although the Yang blazes (normal Yin, excessive Yang). In this case, the treatment
        principle would likely be to sedate the Yang, rather than to nourish the Yin. The goal of
        treatment would be to bring the person back to equilibrium before strengthening their immune
        system to prevent future attacks.
      </Typography>
      <Typography paragraph align="left">
        An example of a <i>relative excess</i> of Yang over Yin is the low-grade fever and night
        sweats associated with menopause. Over the course of a woman's life, she may consume her Yin
        in ways both natural (childbearing, menstrual bleeding) and unnatural (relating to her
        lifestyle, such as smoking, over-consumption of spicy foods, not getting enough sleep). In
        this state, even if her Yang is at normal levels, her deficient Yin will show up as Heat, or
        a relative excess of Yang. In this case, the treatment principle would likely be to nourish
        the Yin, which is deficient, rather than sedating the Yang, which remains at normal levels.
      </Typography>
      <Typography  variant="h5"  align="left" >
        Yin and Yang Separating
      </Typography>
      <Typography paragraph align="left">
        If the first patient's fever were allowed to burn uncontrolled, Yang would eventually
        overpower and consume Yin. Under the most extreme conditions, when Yang is allowed to
        entirely consume Yin, death (in this case by dehydration) will result, as one cannot exist
        without the other. The same would also prove true in the case of prolonged exposure to
        extreme cold, where Yin (cold weather) would eventually overpower Yang (a body's natural
        warmth) and lead to their "separation," death.
      </Typography>
    </React.Fragment>
  )
}
