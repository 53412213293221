import React, {useEffect} from 'react'
import { useDispatch } from 'react-redux'
import {setMobileView} from './app/actions'

import './App.css'
import ResponsiveDrawer from './components/Responsive'

function App() {

  const dispatch = useDispatch()

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 700
        ? dispatch(setMobileView(true))
        : dispatch(setMobileView(false))
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
  }, [dispatch]);

  return (
    <div className="App">
      <ResponsiveDrawer />
    </div>
  )
}

export default App
