import * as actionType from './actionTypes'

export const defaults = {
  mobile: false
}

export const defaultState = { ...defaults }

/**
 * Redux root reducer used to initialize the application's Redux store
 */
export const reducer = (state = defaultState, action) => {
  let result = state
  switch (action.type) {
    case actionType.UPDATE_MOBILE:
      result = { ...state, mobile: action.payload }
      break
    default:
      break
  }
  return result
}
