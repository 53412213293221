import React from 'react'
import Typography from '@material-ui/core/Typography'

import {Link} from "react-router-dom"

export default function Organs() {

  return (
    <React.Fragment>
      <Typography  variant="h5"  align="left" >
        The Organs
      </Typography>
      <Typography paragraph align="left">
        The twelve primary organs, known in Chinese as the <i>zang fu</i>, correspond to the twelve
        primary
        <Link to="/meridians">
          meridians
        </Link>
        and are divided into pairs. There are six solid Yin organs (<i>zang</i>) and six hollow Yang
        organs (<i>fu</i>), with one of each per pair. Qi flows through the organs via their
        respective meridians in the following order: Lung, Large Intestine, Stomach, Spleen, Heart,
        Small Intestine, Urinary Bladder, Kidney, Pericardium, San Jiao (or "Triple Warmer"), Gall
        Bladder, and Liver, before heading back to the Lung and beginning the circuit anew. The Qi
        is said to cycle fifty times every twenty-four hours, flowing through the exterior of the
        body during the day and the Yin organs at night.
      </Typography>
      <Typography paragraph align="left">
        Each of the twelve organs has its own function in TCM theory, often quite distinct from our
        western physiological understanding
      </Typography>
      <Typography  variant="h5"  align="left" >
        Lung
      </Typography>
      <Typography paragraph align="left">
        The Lungs are the most superficial of the Yin organs and are almost always the first organs
        affected by attacks of the external evils, especially Wind and Dryness. The Lungs like to be
        moist and hate to be dry. An overly dry Lung is evidenced by a cough without phlegm or with
        scanty-sticky phlegm, plus dryness in the throat, nose, and mouth, and thirst. The Lungs are
        responsible for spreading moisture to the surface of the skin and to the lower parts of the
        body. They govern Qi and respiration, and, along with the Spleen, manage the body's
        defensive Qi (Wei Qi). The Qi of the Lungs has a descending and dispersing action.
      </Typography>
      <Typography  variant="h5"  align="left" >
        Large Intestine
      </Typography>
      <Typography paragraph align="left">
        The Large Intestine, a Yang organ, is paired with the Lungs. As in western medicine, its
        function is understood to be receiving food and drink from the Small Intestine, absorbing
        water from them, and excreting stool as a waste product. Diseases of the Large Intestine
        include all manner of digestive upsets, as well as more serious disorders such as Crohn's
        disease, ulcerative colitis, and dysentery.
      </Typography>
      <Typography  variant="h5"  align="left" >
        Stomach
      </Typography>
      <Typography paragraph align="left">
        The Stomach, paired with the Spleen, is the most important of the Yang organs. It is in
        charge of "rotting and ripening" our food and drink before passing them to the Spleen, which
        extracts the refined essence of the mix, turns it into Food Qi, and transports it to all
        parts of the body. Without the action of the Stomach, the Spleen cannot effectively do its
        job and our overall Qi is weakened. For this reason, the Stomach is said to be the "origin
        of Qi." Zhang Jie Bin put it this way: "Stomach Qi is the nourishment of life itself. If the
        Stomach is strong, life will be healthy; if the Stomach is weak, life will be unhealthy."
        The Stomach is very closely related to the tongue's coating, and a thin white coat indicates
        healthy Stomach Qi. The major causes of Stomach deficiency (aside from the poor quality of
        the food we eat) are eating late at night and eating on the run. Unlike the Spleen, the
        Stomach likes dampness and dislikes dryness. A dry Stomach leads to Heat, to which the
        Stomach is very susceptible. This may show up in chronic bad breath, mouth and gum sores,
        and strong thirst. The Qi of the Stomach has a descending action, and "rebellious" (i.e.,
        moving in the wrong direction) Stomach Qi can lead to vomiting, acid reflux, belching, and
        hiccups.
      </Typography>
      <Typography  variant="h5"  align="left" >
        Spleen
      </Typography>
      <Typography paragraph align="left">
        Similar to the pancreas in western medicine, the Spleen's main function is transforming food
        and water into food essence and transporting these nutrients to all corners of the body. It
        is said to like dryness and dislike dampness, and when the Spleen Qi is deficient,
        pathogenic Dampness occurs. Pathogenic Dampness is seen in obesity, edema, copious sputum
        (if it rises to the Lungs), and most commonly in poor digestion (loose stools, diarrhea).
        The Spleen is also in charge of maintaining our muscles, promoting clear thinking and good
        memory, and keeping our Blood flowing within the veins. Fatigue is the most common symptom
        of deficient Spleen Qi, while excessive menstrual bleeding, easy bruising, and blood in the
        urine can all be due to "Spleen failing to control the Blood." The Qi of the Spleen has an
        ascending action, and its deficiency is thus associated with prolapse of the organs and
        "sinking" sensations in the body.
      </Typography>
      <Typography  variant="h5"  align="left" >
        Heart
      </Typography>
      <Typography paragraph align="left">
        As in western physiology, the Heart in TCM is said to control Blood circulation. Thus, a
        deficiency of Heart Qi and Blood can result in symptoms of both vessel and organ deficiency,
        including paleness, a weak pulse, and serious symptoms such as angina pectoris and irregular
        heartbeat. The Heart is also said to control mental and emotional activities, predominantly
        as they relate to consciousness and thought. Dysfunction of the Heart can thus lead to
        insomnia, excessive dreaming, mental restlessness, and even delirium. It also controls our
        speech, and thus a person who stutters or talks excessively may be thought to have an
        imbalance (usually Heat) in the Heart organ or channel.
      </Typography>
      <Typography  variant="h5"  align="left" >
        Small Intestine
      </Typography>
      <Typography paragraph align="left">
        The Small Intestine, a Yang organ, is paired with the Heart and is in charge of separating
        clear, useable substances from turbid waste in the food and liquid passed to it from the
        Stomach. The clear substance is distributed by the Spleen (as Food Qi) to all corners of the
        body, while the turbid is sent to the Urinary Bladder and Large Intestine for further
        processing and excretion. If there is dysfunction of the Small Intestine, poor digestion
        will result. Heart Fire can also sometimes transfer to the Small Intestine, in turn causing
        the symptoms of an acute urinary-tract infection due to the Small Intestine's close
        relationship with the Urinary Bladder.
      </Typography>
      <Typography  variant="h5"  align="left" >
        Urinary Bladder
      </Typography>
      <Typography paragraph align="left">
        Paired with the Kidney, the Urinary Bladder is a Yang organ in charge of storing and
        excreting urine, much as we understand it in western medicine. It also has some
        transformative power, receiving "turbid" fluids from the Small Intestine and transforming
        them into urine. Bladder problems commonly lead to urinary incontinence or frequent
        urination.
      </Typography>
      <Typography  variant="h5"  align="left" >
        Kidney
      </Typography>
      <Typography paragraph align="left">
        The Kidneys are often referred to as the "Root of Life," as they store the Essence (known as
        <i>Jing</i>) we inherit from our parents (prenatal Essence) as well as the Essence we get
        from the food we eat and air we breath (postnatal Essence). Essence is the source of
        nourishment for a fetus and later controls our growth, development, sexual maturation, and
        fertility. Infertility and impotence can be due to a lack of Kidney Essence, as can retarded
        growth in children and premature senility in adults. Although we can be born with a
        congenital deficiency of Essence, it is more frequently lost through improper lifestyle
        habits, especially excessive and premature sexual activity. The Kidneys are also the root of
        the body's Yin and Yang, as well as that of our bones and bone marrow, brain, and hearing.
        The Kidneys govern water and Body Fluids, and any problems with the urinary system or other
        aspects of processing and controlling fluids (edema, bedwetting, frequent UTIs) is likely
        related to the Kidneys. The Kidneys are said to house "willpower" and their deficiency can
        lead to indecisiveness and aimlessness. They are also the source of Fire for the whole body;
        this is housed in the Gate of Vitality (<i>Ming Men</i>), situated between the two Kidneys,
        and a decline in this Fire can lead to tiredness, lack of vitality, mental depression, and
        feelings of cold. Sexual function and libido are predominantly a function of Kidney Yang and
        <i>Ming Men</i> Fire. Kidney Qi also aids in "grasping Qi," which helps the Lungs to allow
        us to take deep breaths. Asthmatic patients tend to show Kidney deficiencies.
      </Typography>
      <Typography  variant="h5"  align="left" >
        Pericardium
      </Typography>
      <Typography paragraph align="left">
        The Pericardium, a muscular layer surrounding the Heart, is charged with protecting the
        Heart, the "emperor" of the organs. As such, it is referred to as the "palace of the
        emperor" and is the affected first when the Heart is attacked. Symptoms such as coma and
        delirium are commonly due to Heat attacking the Pericardium. The Pericardium is considered a
        Yin organ and is paired with the San Jiao, its Yang counterpart.
      </Typography>
      <Typography  variant="h5"  align="left" >
        San Jiao
      </Typography>
      <Typography paragraph align="left">
        The San Jiao, or Triple Warmer, is not actually an organ but rather a set of functions
        related to the three divisions of the torso, known as the three <i>jiaos</i>. The upper jiao
        refers to the area above the diaphragm (Lung and Heart), the middle jiao refers to the area
        between the diaphragm and the umbilicus (Spleen and Stomach), and the lower jiao refers to
        the area between the umbilicus and the genitals (Kidney, Liver, Gall Bladder, Intestines,
        and Urinary Bladder). Together, these three areas are known as the San Jiao, and points
        along the San Jiao meridian are used to treat disorders of all three areas. The main
        physiological functions of the San Jiao are controlling the activities of the body's Qi, by
        serving as the passageways through which Qi flows, and regulating the transportation of
        fluids through the body. Basically, it functions like a series of locks in a canal,
        alternately restraining and releasing Qi and fluids as needed.
      </Typography>
      <Typography  variant="h5"  align="left" >
        Gall Bladder
      </Typography>
      <Typography paragraph align="left">
        The Gall Bladder is a Yang organ paired with the Liver, which belongs to Yin. As in western
        medicine, the Gall Bladder stores and excretes bile, while the Liver produces and secretes
        it. Unique to TCM, the Gall Bladder is also said to control courage in decision-making, and
        thus someone who is indecisive can often be treated successfully by treating the Gall
        Bladder meridian. It also has some effect on the quality and length of sleep, and someone
        who lacks Gall Bladder Qi may wake early and have trouble falling back asleep.
      </Typography>
      <Typography  variant="h5"  align="left" >
        Liver
      </Typography>
      <Typography paragraph align="left">
        The Liver is said to regulate the smooth flow of Qi and thus affects the Qi of all the other
        organs and channels. Stagnant Qi (that which is not flowing smoothly) is the cause of many
        diseases and discomforts and is most commonly seen in those whose emotions are easily upset.
        In these situations, stagnant Liver Qi often "attacks" the Spleen, causing digestive upset
        that's worse in times of stress. Anger is especially damaging to the flow of Liver Qi,
        causing it to rise and often bringing with it Heat. This is why a red face and volatile
        emotions are associated with the Liver and why we speak of "rising frustration." The Liver's
        second major job is storing the Blood, which allows it to regulate Blood volume throughout
        the body. The soul is said to be anchored by Liver Blood, the deficiency of which can lead
        to a "lost" or ungrounded feeling, especially at night, when Blood is meant to return to the
        Liver. Menstruation is closely tied to Liver function, and menstrual disorders are not
        uncommon in those with Liver Qi and Blood problems. Finally, the Liver controls the sinews
        and tendons, causing tremors and seizures when in disorder, and is also in charge of the
        nails and partially responsible for our vision.
      </Typography>
    </React.Fragment>
  )
}
