import React from 'react'
import Typography from '@material-ui/core/Typography'

import { Link as MLink} from '@material-ui/core'
import {
  Link
} from "react-router-dom";

export default function Acupuncture() {
  
  return (
    <React.Fragment>
      <Typography  variant="h5"  align="left" >
        What is acupuncture? Does it hurt?
      </Typography>
      <Typography paragraph align="left">
        Acupuncture consists of the insertion of hair-thin, sterile, disposable needles through the
        skin in points along the body's
        <Link to="/meridians">meridians</Link>
        . Acupuncture needles are solid (as opposed to the hollow-bore needles used for injections),
        made of stainless steel, and extremely flexible. Their tiny diameter and pine-needle shape
        allow them to be inserted quickly and painlessly just a few millimeters (deeper in fleshier
        areas). The needles can then be stimulated manually or by the application of heat or
        electrical impulses to further activate a person's
        <Link to="/what_is_qi">
          Qi
        </Link>
        .
      </Typography>
      <Typography  variant="h5"  align="left" >
        What can acupuncture treat?
      </Typography>
      <Typography paragraph align="left">
        A better question might be "What <i>can't</i> acupuncture treat?" TCM treatment focuses on
        the well being of the entire person, not simply on the physical complaints and symptoms, and
        as such can be used either as a primary or a complementary treatment for virtually any
        medical condition known.
      </Typography>
      <Typography paragraph align="left">
        A main difference between the treatment approaches of TCM and western (allopathic) medicine
        is that the TCM practitioner views disease as stemming from an imbalance affecting the whole
        of the body rather than a separate, distinct pathogen to be cut out, irradiated, or
        bombarded with chemicals that oftentimes carry serious side effects. This "holistic"
        approach allows the TCM practitioner to treat both the "branch" (the physical manifestations
        of the disease) while also addressing "root" of the disorder (the imbalance that gave rise
        to the disease in the first place), simultaneously working to treat the disease and prevent
        its recurrence.
      </Typography>
      <Typography paragraph align="left">
        Among the disorders we commonly treat are colds and flus, headaches and migraines, anxiety
        and depression, PMS and menstrual disorders, digestive disturbances, urinary difficulty,
        fertility problems, immune disorders, and all sorts of bodily aches and pains, including
        carpal-tunnel syndrome, stiff neck, chronic and acute back pain, arthritis and joint
        problems, sciatica, muscle strain, sprained ankle, and so on.
      </Typography>
      <Typography  variant="h5"  align="left" >
        The Western Take on Acupuncture
      </Typography>
      <Typography paragraph align="left">
        The
        <MLink href="http://www.who.int/en/" target="_blank">
          World Health Organization
        </MLink>
        (WHO) has developed a list of thirty-plus conditions that lend themselves to treatment by
        acupuncture. Likewise, the
        <MLink href="http://www.nih.gov/" target="_blank">
          National Institute of Health
        </MLink>
        (NIH) has touted the "
        <MLink href="/NIHconsensus.pdf" target="_blank">
          efficacy of acupuncture
        </MLink>
        in (treating) adult post-operative and chemotherapy nausea and vomiting and in
        post-operative dental pain. There are other situations such as addiction, stroke
        rehabilitation, headache, menstrual cramps, tennis elbow, fibromyalgia, myofascial pain,
        osteoarthritis, low back pain, carpal tunnel syndrome, and asthma for which acupuncture may
        be useful as an adjunct treatment or an acceptable alternative or be included in a
        comprehensive management program. Further research is likely to uncover additional areas
        where acupuncture interventions will be useful."
      </Typography>
      <Typography  variant="h5"  align="left" >
        Do I need to be sick to try acupuncture?
      </Typography>
      <Typography paragraph align="left">
        Far from it. In fact, the Chinese have an ancient proverb that states "Treating an illness
        once symptoms arise is like digging a well when you're thirsty." The beauty of TCM is that
        its fundamental goal is to bring the body back to a state of balance&#8212;
        <i>homeostasis</i>&#8212;and who among us wouldn't benefit from a rebalancing?
      </Typography>
      <Typography paragraph align="left">
        It's a widely recognized fact that stress is the root cause of many, if not most, of our
        illnesses, and acupuncture is a wonderful tool to combat stress. Many of my patients come in
        for weekly or monthly "tune-ups" even when they're not feeling particularly out of sorts.
        Combined with a moderate lifestyle, a wholesome diet, regular exercise, and a positive
        outlook, TCM treatments can help keep you healthy, hardy, and spry despite the chaotic
        influences of the modern world.
      </Typography>
      <Typography  variant="h5"  align="left" >
        How long will my treatment take?
      </Typography>
      <Typography paragraph align="left">
        It varies with each patient. Many acute conditions, such as catching a cold or spraining an
        ankle, tend to respond well and quickly to prompt treatment, while chronic or longer-term
        disorders (PMS, depression, eczema) may take longer to resolve. Over the course of
        treatment, however, you should see an overall improvement in your health while your chief
        complaint is addressed.
      </Typography>
      <Typography  variant="h5"  align="left" >
        Is acupuncture safe?
      </Typography>
      <Typography paragraph align="left">
        Acupuncture is extremely safe. Serious side effects are very rare&#8212;less than one
        incident per 10,000 treatments. The most common side effect of acupuncture, which itself is
        not terribly common, is minor bruising at the insertion site. And we can treat that too.
      </Typography>
      <Typography paragraph align="left">
        To minimize the risk of infection, only sterile, single-use needles are used in our clinic.
      </Typography>
      <Typography  variant="h5"  align="left" >
        Is there anything else a practitioner needs to know?
      </Typography>
      <Typography paragraph align="left">
        Apart from the usual medical details, it is important that you tell me:
        <ul>
          <li> If you are pregnant or become pregnant over the course of your treatment </li>
          <li> if you have ever experienced a seizure or have fainted</li>
          <li> if you have a pacemaker or any other electrical implant</li>
          <li> if you have a bleeding disorder or are taking anticoagulants</li>
          <li> if you have damaged heart valves or any other particular risk of infection</li>
        </ul>
      </Typography>
    </React.Fragment>
  )
}
