import React from 'react'
import Typography from '@material-ui/core/Typography'

import {Link} from "react-router-dom"
import { Link as MLink} from '@material-ui/core'


export default function FeesAndBilling() {
  
  return (
    <React.Fragment>
      <Typography  variant="h5"  align="left" >
        Office Fees
      </Typography>
      <Typography paragraph align="left">
        For those paying at the time of service, an initial consultation with a new client,
        including examination, discussion, and an acupuncture treatment, is $200. Follow-up
        treatments are $120. I may recommend
        <Link
          to="/herbal_medicine"
        >
          herbal medicines
        </Link>
        or nutritional supplements, which can be provided for an additional fee. The cost of any
        herbs or supplements (usually around $20 per week) will be discussed with you prior to
        preparing a custom herbal formula. Please be aware that unused herbs and supplements cannot
        be returned for refund or exchange.
      </Typography>
      <Typography  variant="h5"  align="left" >
        Does Insurance Cover Acupuncture?
      </Typography>
      <Typography paragraph align="left">
        Many insurance plans, health-savings accounts, and employer-sponsored health-benefits
        packages do reimburse for acupuncture, but each plan differs in which services and
        conditions are covered and for how many sessions. If you'd like me to try billing your
        insurance rather than paying for acupuncture out of pocket, please enter your full name,
        address, date of birth, and all the information from your insurance card (personal ID
        number, group ID number, and the name and phone number of your insurance company) into
        <MLink
          href="https://www.enterverification.com/pb/pbt.php?pk=lsf89ahVwoDiPxm"
          rel="noreferrer"
          target="_blank"
        >
          this form
        </MLink>
        , and my billers will verify your benefits (which conditions are covered, how many
        treatments per year, your deductible, your co-pay, etc). Please do this at least one full
        business day prior to your appointment, preferably sooner.
      </Typography>
      <Typography  variant="h5"  align="left" >
        Workers' Comp and Personal Injury
      </Typography>
      <Typography paragraph align="left">
        Please contact me if you qualify for either workers' compensation or personal-injury
        treatments.
      </Typography>
      <Typography  variant="h5"  align="left" >
        Referrals
      </Typography>
      <Typography paragraph align="left">
        The success of the Grand Acupuncture Center depends upon the satisfaction of our patients,
        and your referrals constitute the backbone of our patient base. Thank you for your faith and
        support, and please let us know if you have a friend you'd like us to contact.
      </Typography>
    </React.Fragment>
  )
}
