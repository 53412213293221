import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Link } from '@material-ui/core'
import Iframe from 'react-iframe'

export default function Directions() {
  return (
    <React.Fragment>
      <Iframe url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.7768051550315!2d-122.24730944856434!3d37.81869657965225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808f875da934e6df%3A0x52c2ee715c404b1a!2s3931%20Grand%20Ave%2C%20Oakland%2C%20CA%2094610!5e0!3m2!1sen!2sus!4v1620017439777!5m2!1sen!2sus"
        width="100%"
        height="300px"
        id="googlemap"
        frameBorder="1"
        display="initial"
        position="relative"/>
      <Typography  variant="h5"  align="left" >
        Parking
      </Typography>
      <Typography paragraph align="left">
        There are two "Acupuncture Parking" spots for the clinic in the lot behind the building,
        plus a third spot that is labelled for dental or acupuncture patients. Please help us keep
        on good terms with our neighbors -- do not park in any other tenant's spot, even briefly. In
        case our spots are taken, please allot enough time to find street parking. (We've never had
        to park more than a block away, and the neighborhood is very safe for walking, even at
        night.)
      </Typography>
      <Typography paragraph align="left">
        There are electronic pay-and-display meters along Grand Avenue, while parking along other
        nearby streets is free. Please keep an eye on the signs, as all the local side-streets have
        posted street-sweeping hours, and parking citations are not cheap.
      </Typography>
      <Typography  variant="h5"  align="left" >
        By Bike
      </Typography>
      <Typography paragraph align="left">
        If you'll be cycling to the clinic, there are bike anchors out front to which you can lock
        your bike, or you can take it around to the back side of the building and lock it to the
        posts at the side of the driveway. We regret there is no room available for bike storage
        within the clinic, so please bring your own lock.
      </Typography>
      <Typography  variant="h5"  align="left" >
        By BART or Bus
      </Typography>
      <Typography paragraph align="left">
        The MacArthur BART station is located on 40th Street between Martin Luther King Boulevard
        and Telegraph Avenue, about thirty minutes' walk or a ten-minute cab ride from our clinic.
        We're happy to call a cab for you when your treatment is over.
      </Typography>
      <Typography paragraph align="left">
        The intersection of Grand Ave and Jean Street is served by AC Transit bus number 12, which
        goes to/from the MacArthur and 12th Street BART stations. The bus from MacArthur stops
        immediately in front of the clinic; the bus from 12th Street stops across the street.
      </Typography>
      <Typography paragraph align="left">
        For detailed information on BART or AC Transit routes and schedules, visit
        <Link href="http://www.511.org/" rel="noreferrer" target="_blank">
          511.org
        </Link>
        .
      </Typography>
    </React.Fragment>
  )
}
