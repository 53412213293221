import React from 'react'
import Typography from '@material-ui/core/Typography'
import Carousel from 'react-material-ui-carousel'
import { Paper, CardMedia } from '@material-ui/core'

import IMG_3690 from '../../images/clinic/IMG_3690.jpg'
import IMG_3694 from '../../images/clinic/IMG_3694.jpg'
import IMG_3696 from '../../images/clinic/IMG_3696.jpg'
import IMG_3697 from '../../images/clinic/IMG_3697.jpg'
import IMG_3699 from '../../images/clinic/IMG_3699.jpg'
import IMG_3700 from '../../images/clinic/IMG_3700.jpg'
import IMG_3701 from '../../images/clinic/IMG_3701.jpg'
import IMG_3703 from '../../images/clinic/IMG_3703.jpg'
import IMG_3714 from '../../images/clinic/IMG_3714.jpg'

import './OurClinic.css'

const items = [
  {
    name: 'Waiting Room',
    description: 'Waiting Room',
    image: IMG_3690
  },
  {
    name: 'Waiting Room',
    description: 'Waiting Room',
    image: IMG_3694
  },
  {
    name: 'Office',
    description: 'Office',
    image: IMG_3696
  },
  {
    name: 'Treatment Room 1',
    description: 'Treatment Room 1',
    image: IMG_3697
  },
  {
    name: 'Treatment Room 2',
    description: 'Treatment Room 2',
    image: IMG_3699
  },
  {
    name: 'Chinese Herbs',
    description: 'Chinese Herbs',
    image: IMG_3701
  },
  {
    name: 'Treatment Room 3',
    description: 'Treatment Room 3',
    image: IMG_3700
  },
  {
    name: 'Treatment Room 4',
    description: 'Treatment Room 4',
    image: IMG_3703
  },
  {
    name: 'Custom Murals',
    description: 'Custom Murals',
    image: IMG_3714
  }
]

const ShowPhoto = ({ item }) => {
  return (
    <Paper className="Project" elevation={10}>
      <CardMedia className="Media" image={item.image} title={item.name}>
        <Typography className="MediaCaption">{item.description}</Typography>
      </CardMedia>
    </Paper>
  )
}
export default function OurClinic() {
  return (
    <React.Fragment>
      <Typography  variant="h5"  align="left" >
        Grand Acupuncture Center
      </Typography>
      <Typography paragraph align="left">
        My practice is located in the Grand Acupuncture Center, a holistic healing space centrally
        located in the Grand Lake neighborhood of Oakland, near to Piedmont, Lakeshore, Rockridge,
        and downtown Oakland. We offer four treatment rooms and a fully stocked herbal pharmacy to
        treat a wide range of conditions. The suite is on the second floor of the building, at the
        top of the stairs (alas, there is no elevator to the building, but the stairs are wide and
        have a handrail). We share the building with a dentist's office and an orthodontist; you'll
        recognize us by the whimsical river-rock sculptures that adorn the property.
      </Typography>
      <Carousel
        autoPlay={true}
        animation="fade"
        indicators={true}
        timeout={500}
        navButtonsAlwaysVisible={true}
        navButtonsAlwaysInvisible={false}
      >
        {items.map((item, index) => {
          return <ShowPhoto item={item} key={index} />
        })}
      </Carousel>
    </React.Fragment>
  )
}
