import React from 'react'
import Typography from '@material-ui/core/Typography'
import lockstate from '../../images/lockstate.jpg'

import {Link} from "react-router-dom"

export default function YourFirstVisit() {

  return (
    <React.Fragment>
      <Typography  variant="h5"  align="left" >
        Before Your Treatment&#8212;Your "Homework"
      </Typography>
      <Typography paragraph align="left">
        To allow us to spend more time in discussion and treatment, please download, print out, and
        take your time to thoroughly fill out the
        <Link to="/patient_forms">
          health-history forms
        </Link>
        and bring them with you to your first appointment. If you are unable to print out the forms,
        I can mail them to you in advance or you can arrange to arrive fifteen minutes early for
        your treatment and fill them out at the clinic. To facilitate your treatment, please wear
        loose, comfortable clothing that can be rolled back enough to expose your elbows and knees.
        It's a good idea to have a light meal before acupuncture, but try not to arrive
        uncomfortably full. Avoid consuming alcohol before and immediately after your visit;
        likewise, avoid strenuous exercise. Please try to arrive a few minutes before your treatment
        is scheduled to begin so as to be relaxed and receptive at the appropriate time. If you find
        you have to cancel a treatment, please give me at least twenty-four hours' notice.
      </Typography>
      <Typography  variant="h5"  align="left" >
        Upon Arrival
      </Typography>
      <Typography paragraph align="left">
        The code for the keypad lock on the clinic's front door is the same as our address: press
        3-9-3-1, then the big Lockstate button on top. This will open the deadbolt, and you can use
        the handle below to let yourself in. If you forget the code, use the doorbell to the right
        (sparingly, please ... it's distracting for other clients). <br />
      </Typography>
      <img
        src={lockstate}
        style={{ align: 'left' }}
        alt="Front Door Lockstate Lock"
        width="200"
        height="200"
      ></img>
      <Typography  variant="h5"  align="left" >
        Health-History Interview &amp; Exam
      </Typography>
      <Typography paragraph align="left">
        Allow about two hours for your first treatment to give us ample time to gather a thorough
        health history and chart all of the details relevant to the condition(s) that you want
        treated. During that time, I'll ask you questions about your family and birth history; your
        lifestyle, diet, and exercise routines; your mental state and emotions; any physical pain or
        discomfort you're having; your work; your play; your sleep patterns; and so on. The
        physical-exam portion of the visit will include palpation of your pulse and abdomen,
        examination of your tongue (size, coating, color) and face (coloration, skin tone,
        vitality), as well as more familiar examinations, such as taking vital signs (temperature
        and blood pressure) and height and weight measurements. In short, the more information we
        collect, the clearer the picture of your overall health becomes and the more accurate the
        resulting diagnosis. It's important we take the time during your first visit to make sure we
        have a complete picture.
      </Typography>
      <Typography paragraph align="left">
        From this information I'll formulate a baseline diagnosis that will guide the treatment,
        helping me choose which acupuncture points, herbs, and other treatment modalities to use
        that day and in future visits. Of course, everything we discuss in relation to your health
        is held in
        <Link to="/privacy"
        >
          strict confidence
        </Link>
        and will be shared with no one without your written consent, except as required by law.
      </Typography>
      <Typography  variant="h5"  align="left" >
        During the Treatment
      </Typography>
      <Typography paragraph align="left">
        After the health-history interview, I'll have you lie down on the massage table and we'll
        begin the acupuncture. Most people receive between six and twelve needles in a visit, though
        some treatments require as many as twenty. As mentioned in the
        <Link
          to="/acupuncture"
        >
          About Acupuncture
        </Link>
        section, acupuncture involves the gentle insertion and stimulation of extremely fine,
        disposable, sterile needles at strategic points near the surface of the body, the goal of
        which is to elicit a
        <Link
         to="/qi_sensation"
        >
          Qi response
        </Link>
        . While some patients are initially nervous and wary of the claim that acupuncture doesn't
        hurt, nearly all come to find the experience quite pleasurable and calming. Many patients
        return for weekly or monthly "tune-ups" for that reason alone. After the needles are
        inserted, you will be left alone to relax in the treatment room for between fifteen and
        thirty minutes, though some conditions require longer treatment. Heat lamps, eye pillows,
        and soothing music are available, should you want them, to help you rest.
      </Typography>
      <Typography paragraph align="left">
        While you're relaxing, I'll put together an
        <Link
          to="/herbal_medicine"
        >
          herbal prescription
        </Link>
        for you, if appropriate. At our clinic, herbs are dispensed in convenient granule (powder)
        or pill form. If you'd prefer to decoct a raw-herb tea, or if I don't have the prescribed
        herbs on hand, I can send you to an herbal pharmacy in Berkeley, Oakland, or San Francisco,
        or, if you prefer, I can have the appropriate herbs sent directly to your home (costs a bit
        more, of course).
      </Typography>
      <Typography  variant="h5"  align="left" >
        After the Treatment
      </Typography>
      <Typography paragraph align="left">
        Though most people feel extremely relaxed after acupuncture, some report feeling a bit
        lightheaded. If this happens to you, please rest awhile in the waiting room. It will pass in
        short order.
      </Typography>
      <Typography paragraph align="left">
        Some patients occasionally experience a worsening of their symptoms after an acupuncture
        treatment. This can be a part of the healing process and is usually soon followed by a
        marked improvement in overall wellbeing. Even still, please contact me or my office if you
        have any concerns or feel any unpleasant effects after your visit.
      </Typography>
      <Typography  variant="h5"  align="left" >
        A Few Notes on Tongue Diagnosis
      </Typography>
      <Typography paragraph align="left">
        Please do not brush or scrape your tongue coat before your treatment. The tongue's natural
        coating is a primary TCM diagnostic tool that, once brushed off, is lost to us for the day.
        Coffee, cigarettes, and artificially colored foods&#8212;while not praiseworthy habits under
        any circumstance&#8212;can also stain your tongue coat and are best avoided before a
        treatment.
      </Typography>
    </React.Fragment>
  )
}
