import React from 'react'
import {useSelector} from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import littleyyellowchop from '../images/littleyyellowchop.gif'
import { Link, Box } from '@material-ui/core'

export default function Home() {
  const mobileView = useSelector((state) => state.mobile)


  return (
    <React.Fragment>
     <div className="content">
      <Grid
  container
  direction="row"
  justify="space-between"
  alignItems="flex-start"
  itemscope
    itemtype="https://schema.org/LocalBusiness"
>
  <Link href="https://goo.gl/maps/3tpKHUAhPxawLW6o8" target="_blank" style={{paddingBottom:'10px', paddingLeft: '0px', textDecoration: 'none', color:'#493118'}}
  itemprop="address" itemscope itemtype="https://schema.org/PostalAddress">
    Grand Acupuncture Center
    <br />
    <span itemprop="streetAddress">3931 Grand Ave, 2nd Floor</span>
    <br />
    <span itemprop="addressLocality" className="rightPad">Oakland</span><span itemprop="addressRegion" className="rightPad">CA</span><span itemprop="postalCode">94610</span>
  </Link>
  <div>
  <Typography paragraph align="left" itemprop="openingHours" datetime="Mo, We, Fr 10:00-18:00">
        Monday, Wednesday, and Friday<br/>
        10am - 6pm
      </Typography>
  </div>
  {mobileView ? null :
  <Link href="http://www.yelp.com/biz/don-gates-lac-oakland" target="_blank">
        <img
          src="https://images.yelp.com/extimg/fsc/TmfCOd_rhfZfUk91xwy-vw.png"
          width="125"
          height="54"
          alt="Don Gates, LAc"
        />
      </Link>}
</Grid>      
<Typography paragraph align="left">
        Welcome, and thank you for visiting the home of East Bay Traditional Chinese Medicine.
      </Typography>
      <Typography paragraph align="left">
      My practice is located in the Grand Acupuncture Center, a holistic healing space centrally 
      located in the Grand Lake neighborhood of Oakland, near to Piedmont, Lakeshore, Rockridge, 
      and downtown Oakland. We offer four treatment rooms and a well-stocked herbal pharmacy 
      to treat a wide range of conditions. The suite is located on the second floor, just at the top of the stairs.  
      </Typography>
     
      <Typography  variant="h5" align="left" >
        COVID Precautions
      </Typography>
      <Typography paragraph align="left">
      If you'd like to come in for treatment, please note that among my patients are many with health 
      issues that put them at increased risk of coronavirus infection and complications.  If you show 
      any signs of viral illness or have been in recent contact with anyone who shows signs of illness, 
      please do not come into the clinic. For all others, please wear a comfortable mask at all times 
      and wash or sanitize your hands immediately upon arrival. We maintain HEPA filters in each of 
      our treatment rooms and sanitize each room between patient visits.
      </Typography>
      <Box display="flex" width="100%" height={51}>
        <Box m="auto">
        <img
          src={littleyyellowchop}
          style={{ alignSelf: 'center' }}
          alt="Chop"
          width="50"
          height="51"
        />
        </Box>
      </Box>
      </div>
      <Typography className="footer" variant="body2" align="center">
        Website and text {'Copyright © '}
        {new Date().getFullYear()}
        Don F Gates. Site by Tamar Cohen. Logo by
        <Link style={{color:'#493118', fontSize: '0.8rem', fontWeight: '600'}} href="https://www.hughillustration.com/" target="_new">
          Hugh D'Andrade.
        </Link>
      </Typography>
    </React.Fragment>
  )
}
