import React from 'react'
import Typography from '@material-ui/core/Typography'

export default function WhyPractice() {
  return (
    <React.Fragment>
      <Typography  variant="h5"  align="left" >
        Why I Chose the Path of Traditional Chinese Medicine
      </Typography>
      <Typography paragraph align="left">
        South Pasadena is home to a homogeneous society; not culturally nor in terms of ethnicity,
        but in the residents' shared goal of crafting a perfect slice of Norman Rockwell Americana
        out of an otherwise unremarkable suburb of Los Angeles. I spent most of my first eighteen
        years in South Pasadena, among children of several dozen different ethnic backgrounds all
        striving for the same degree of monoculture you might see in a Benetton advertisement. It
        thus came as a surprise to me when, during my first year in high school, I began to hear
        stories of Mister Goto's senior-level Asian Cultures class.
      </Typography>
      <Typography paragraph align="left">
        Mister Goto was one of those "talked about" teachers, so confident in his manner that he
        couldn't help but be respected among the students. To top it off, although it was said to be
        the hardest humanities class on campus, his course was the only one that the students all
        but universally looked forward to&#8212;shirkers, perhaps, excepted. Mister Goto taught the
        difference between Islam and Hinduism, when most of us didn't know there was one, if we'd
        heard of them at all. He spoke of Jains and Sikhs and Shintoists and of the meaning behind
        the yin-yang symbol and the belly on the Buddha. Mister Goto's students took notes in his
        class, per his advice, on 3-by-5 notecards and carried shoeboxes full of them around the
        school, lest they be lost, stolen, or somehow damaged. Students were known to break down in
        tears when their boxes accidentally spilled a semester's structuring just before a big exam.
        In all, people took Mister Goto's class as seriously as they took anything at that age, and
        a good deal more seriously than most.
      </Typography>
      <Typography paragraph align="left">
        That class introduced me, a white-bread Boy Scout from Pleasantville, to Eastern religion
        and philosophy, peeling away the Otherness of "exotic East" and replacing it with a piqued
        curiosity and a living sense of the mysteries to be found outside the Western philosophical
        canon. From South Pasadena I went on to UC Santa Cruz, where I studied tai chi, Confucian
        and Taoist literature, and other aspects of Eastern culture. Some time after I left Santa
        Cruz, I met a sixty-year-old Spaniard who had practiced traditional Chinese medicine (TCM)
        in France and Spain for over twenty years. His were the first acupuncture needles that found
        their way into my experience, with moxibustion and Chinese herbs soon to follow. I was
        amazed, as are most upon their first experience, at their healing abilities and followed up
        my treatments in Spain with others in Berkeley when I came back to the US.
      </Typography>
      <Typography paragraph align="left">
        It wasn't until after a year's worth of appointments with Patricia Lollis, a fine Bay Area
        acupuncturist, that I began to notice the similarities between her love for her work and
        that of Tom&aacute;s in Spain. Both had been practicing for longer than I'd been earning my
        own living and still both adored their practice. I, on the other hand, had been working as a
        writer and editor of travel guides for a total of five years by then and was already feeling
        oppressed by the routine. Sitting in front of a computer monitor, pulling prose from
        scattered facts and figures for eight hours a day had left me with a decent sense of
        geography, sore wrists and eyes, and little in the way of obvious alternative prospects. The
        idea of practicing TCM as a viable path for my own life&#8212;as opposed to relying on it to
        repair the toll of my lifestyle&#8212;came to me at the end of that year as a welcome
        epiphany. In retrospect, I find it hard to believe it didn't occur to me earlier.
      </Typography>
      <Typography paragraph align="left">
        I went on to study at San Francisco's American College of Traditional Chinese Medicine
        (ACTCM) for four years, where my understanding of the rich traditions of TCM both deepened
        and broadened considerably and my interest in the subject continued to grow. After
        graduation, I built upon this experience by traveling to Chengdu, the capital of Sichuan
        Province in China, to study in a TCM hospital for three months. There, I found I'd been well
        prepared by my studies and, to my surprise, discovered that our school has a reputation for
        turning out well-trained practitioners even in Chengdu, one of China's five "Ivy League" TCM
        universities.
      </Typography>
      <Typography paragraph align="left">
        Now, having graduated and passed both the state and national licensing examinations, I feel
        my education has just begun. With a lifetime opportunities before me to learn and grow as a
        practitioner, I'm as excited today about my studies as I was when I began Mister Goto's
        class as a teenager. Only this time, the classroom will be my own clinic and my patients
        will be my teachers.
      </Typography>
    </React.Fragment>
  )
}
