import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'

import loggerMiddleware from 'redux-logger'
import promiseMiddleware from 'redux-promise-middleware'

import { reducer } from './reducer'

const store = (preloadedState, useMiddleware = true) => {
  const middleware = [
    promiseMiddleware,
    ...getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false
    })
  ]
  if (useMiddleware && process.env.NODE_ENV === 'development') {
    middleware.push(loggerMiddleware)
  }

  const store = configureStore({
    reducer,
    middleware,
    preloadedState
  })

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducer', () => store.replaceReducer(reducer))
  }

  return store
}

export default store