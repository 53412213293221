import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Link } from '@material-ui/core'

export default function Links() {
  return (
    <React.Fragment>
      <Typography  variant="h5"  align="left" >
        Governing &amp; Legislative Bodies
      </Typography>
      <Typography paragraph align="left">
        The
        <Link href="http://www.acupuncture.ca.gov/" target="_blank">
          California Acupuncture Board
        </Link>
        is the licensing and regulating body for the state's acupuncture profession. California is
        currently the only state with its own licensing exam.
      </Typography>
      <Typography paragraph align="left">
        The
        <Link href="http://www.nccaom.org/" target="_blank">
          National Certification Commission for Acupuncture and Oriental Medicine
        </Link>
        (NCCAOM) is the agency in charge of testing competency in TCM practitioners in the 49 states
        that don't require their acupuncturists to pass a state-level licensing exam.
      </Typography>
      <Typography paragraph align="left">
        The
        <Link href="http://www.csomaonline.org/" target="_blank">
          California State Oriental Medicine Association
        </Link>
        (CSOMA) is the state's most active political organization representing the gamut of Oriental
        medicine's concerns.
      </Typography>
      <Typography paragraph align="left">
        The
        <Link href="http://www.aaom.org/" target="_blank">
          American Association of Oriental Medicine
        </Link>
        (AAOM) is a nationwide association of TCM and other Oriental medicine practitioners.
      </Typography>
      <Typography  variant="h5"  align="left" >
        Scientific Research on TCM
      </Typography>
      <Typography paragraph align="left">
        The NIH's
        <Link href="https://www.nccih.nih.gov/health/acupuncture-in-depth" target="_blank">
          National Center for Complementary and Alternative Medicine
        </Link>
        (NCCAM) is the nation's top agency for scientific research on complementary and alternative
        medicine. Their mission is "to explore complementary and alternative healing practices in
        the context of rigorous science, to train CAM researchers, and to inform the public and
        health professionals about the results of CAM research studies."
      </Typography>
      <Typography  variant="h5"  align="left" >
        Publishers &amp; Purveyors
      </Typography>
      <Typography paragraph align="left">
        <ul className="noBullets">
          <li>
            
            <Link href="http://www.bluepoppy.com/" target="_blank">
              Blue Poppy Press
            </Link>
          </li>

          <li>
            
            <Link href="http://www.asiabookcenter.com/" target="_blank">
              Eastwind Books of Berkeley
            </Link>
          </li>

          <li>
            
            <Link href="http://www.redwingbooks.com/" target="_blank">
              Redwing Book Company
            </Link>
          </li>

          <li>
            
            <Link href="http://www.eastlandpress.com/" target="_blank">
              Eastland Press
            </Link>
          </li>

          <li>
            
            <Link href="http://www.shambhala.com/" target="_blank">
              Shambhala Publications
            </Link>
          </li>

          <li>
            
            <Link href="http://www.eastwestbooks.com/" target="_blank">
              East-West Books
            </Link>
          </li>

          <li>
            
            <Link href="http://www.taostar.com/" target="_blank">
              Tao Star
            </Link>
          </li>

          <li>
            
            <Link href="http://www.acuneeds.com/" target="_blank">
              Acuneeds
            </Link>
          </li>

          <li>
            
            <Link href="http://www.paradigm-pubs.com/" target="_blank">
              Paradigm Publications
            </Link>
          </li>
        </ul>
      </Typography>
      <Typography  variant="h5"  align="left" >
        Local Holistic Health Resources
      </Typography>
      <Typography paragraph align="left">
        <ul className="noBullets">
          <li>
            
            <Link href="http://www.bethbaron.com/" target="_blank">
              Beth Baron, massage therapist
            </Link>
          </li>

          <li>
            
            <Link href="http://www.kartchchiro.com" target="_blank">
              Karen Kartch, chirporactor
            </Link>
          </li>

          <li>
            
            <Link href="https://schoolofintuitivestudies.com/" target="_blank">
              Wendy DeRosa, intuitive-energy healer, reiki
            </Link>
          </li>

          <li>
            
            <Link href="http://www.eastbayces.com" target="_blank">
              Elisa Dumesnil, marriage &amp; family therapist
            </Link>
          </li>
        </ul>
      </Typography>
      <Typography  variant="h5"  align="left" >
        Pregnancy &amp; Postpartum Resources
      </Typography>
      <Typography paragraph align="left">
        <ul className="noBullets">
          <li>
            
            <Link href="http://www.everymothermassage.com/" target="_blank">
              Gina Marie Buonpane, pre- and post-natal massage
            </Link>
          </li>

          <li>
            
            <Link href="http://www.movingfromwithin.com/" target="_blank">
              Kim Lyons, massage, doula, and childhood-development services
            </Link>
          </li>

          <li>
            
            <Link
              href="http://www.bayareabirth.org/baba_resources/detail.php?siteid=106"
              target="_blank"
            >
              Kelly Keller &amp; Michele Bloom, multilingual doula services
            </Link>
          </li>
        </ul>
      </Typography>
    </React.Fragment>
  )
}
