import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Link } from '@material-ui/core'
import don2 from '../../images/don2.jpg'

export default function MyBackground() {
  return (
    <React.Fragment>
      <img src={don2} alt="Don doing acupuncture" width="400" height="310" border="0"></img>
      <Typography  variant="h5"  align="left" >
        Education
      </Typography>
      <Typography paragraph align="left">
        I received my Master of Science degree in traditional Chinese medicine at the
        <Link href="http://www.actcm.edu/" target="_blank">
          American College of Traditional Chinese Medicine
        </Link>
        in San Francisco, California. My degree program included over 3000 hours of clinical and
        classroom training, including extensive instruction in the theories and practice of
        traditional Chinese medicine (TCM) as it encompasses acupuncture, herbology, nutrition,
        massage, and energetics (tai chi and qi gong). Western principles of anatomy, physiology,
        pathophysiology, and pharmacology were also a significant part of our training.
      </Typography>
      <Typography paragraph align="left">
        In addition, I spent three months in an internship in Sichuan Province at the teaching
        hospital of the
        <Link href="http://www.cdutcm.org" target="_blank">
          Chengdu University of Traditional Chinese Medicine
        </Link>
        , one of the top TCM schools in China. There, my time was divided mainly between the
        departments of cancer, diabetes, and liver/gallbladder disorders.
      </Typography>
      <Typography  variant="h5"  align="left" >
        Professional Licensure
      </Typography>
      <Typography paragraph align="left">
        I am licensed by the
        <Link href="http://www.acupuncture.ca.gov/" target="_blank">
          California Acupuncture Board
        </Link>
        (Lic.# AC 8908) and am a diplomate in Chinese Herbology through the
        <Link href="http://www.nccaom.org" target="_blank">
          National Certification Commission for Acupuncture and Oriental Medicine
        </Link>
        (NCCAOM). I remain an active professional member in good standing in both organizations.
      </Typography>
    </React.Fragment>
  )
}
