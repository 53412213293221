import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import { Link } from '@material-ui/core'
import Popover from '@material-ui/core/Popover'

import wufudishred from '../../images/wufudishred.jpg'
import bluewufu from '../../images/bluewufu.jpg'
import clothbat from '../../images/clothbat.jpg'
import wufubrick from '../../images/wufubrick.jpg'

import batman1 from '../../images/dynamicduo.jpg'
import batman2 from '../../images/miller-batman.jpg'
import batman3 from '../../images/batman.jpg'

export default function MyLogo() {
  const [anchorEl, setAnchorEl] = useState(null)
  const [popoverId, setPopoverId] = useState(undefined)
  const [popoverImage, setPopoverImage] = useState(null)
  const open = Boolean(anchorEl)

  const openPopup = (el, id, image) => {
    setAnchorEl(el.currentTarget)
    setPopoverId(id)
    setPopoverImage(image)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <React.Fragment>
      <Typography  variant="h5"  align="left" >
        Bats in Chinese Art
      </Typography>
      <Typography paragraph align="left">
        In the Mandarin tongue, the character for "bat" is pronounced the same as the one for
        "happiness"&#8212;<i>f&uacute;</i>&#8212;and from that play on words, bats have become
        associated with good fortune. When shown singly, bats represent happiness and are often
        depicted in bright red, the color of joy. When five bats are seen together in Chinese art,
        typically encircling the stylized ideogram known as the prosperity symbol, they represent
        the Five Blessings: health, wealth, virtuousness, longevity, and a peaceful, natural death.
      </Typography>
      <img
        src={wufudishred}
        alt="Red Wu Fu Dish"
        width="144"
        height="144"
        border="0"
        className="rightPad"
      />
      <img src={bluewufu} alt="Blue Wu Fu" width="144" height="144" border="0" />
      <Typography paragraph align="left">
        This popular motif is traditionally embroidered on fine silk clothing to imply that the
        wearer's prosperity results from a virtuous lifestyle. For much the same reason, it's also a
        recurrent theme in traditional tile- and brick-work.
      </Typography>
      <img
        src={clothbat}
        alt="Cloth Bat"
        width="144"
        height="144"
        border="0"
        className="rightPad"
      />
      <img src={wufubrick} alt="Wu Fu Brick" width="144" height="144" border="0" />
      <Typography paragraph align="left">
        The leap from the traditional Chinese concept of the Five Blessings to the fundamental TCM
        theory of the Five Elements (Wood, Fire, Earth, Metal, Water) seems to me a natural
        extension of the symbol's meaning. Further, with the average American associating the bat
        less with fortune and benevolence than with nightmares and evil&#8212;Dracula, Halloween,
        <Link href="#" onClick={(e) => openPopup(e, 'Batman', batman1)}>
          Batman
        </Link>
        (okay, so the campy 1960s TV series' Batman wasn't all that scary, but tell that to
        <Link href="#" onClick={(e) => openPopup(e, 'Frank Miller', batman2)}>
          Frank Miller
        </Link>
        and
        <Link href="#" onClick={(e) => openPopup(e, 'Dave McKean', batman3)}>
          Dave McKean
        </Link>
        )&#8212;I feel that bats deserve all the good press they can get.
      </Typography>
      <Typography paragraph align="left">
        In light of that, I encourage you to visit (and support) the fabulous
        <Link href="http://www.batcon.org" target="_blank">
          Bat Conservation International
        </Link>
        , where you can learn more about bats and their importance to our ecology and economy.
      </Typography>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <img src={popoverImage} alt={popoverId} />
      </Popover>
    </React.Fragment>
  )
}
