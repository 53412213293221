import React from 'react'
import Typography from '@material-ui/core/Typography'

export default function AllAboutTCM() {
  return (
    <React.Fragment>
      <Typography  variant="h5"  align="left" >
        All About Traditional Chinese Medicine
      </Typography>
      <Typography paragraph align="left">
        Some people simply glow with health. Their eyes shine and sparkle. Their hair is lustrous.
        Their skin is strong and clear. Ask these people and you're likely to hear that their energy
        level is consistent and their emotions even-keeled. They rarely get sick, and when they do,
        it doesn't last long. Other people are not so robust. They may catch colds easily and often
        or struggle to get through the day without their energy "crashing." Some don't sleep well at
        night, while others never seem to fully wake up. These people may have poor digestion,
        erratic emotions, and/or aches and pains that just won't go away. If this latter type of
        person goes to a western physician for help, they're likely to be put through a battery of
        examinations and may still be told there's "nothing wrong" with them. It may be implied that
        their ails are psychosomatic&#8212;in effect, that their problems are all in their
        head&#8212;or stranger still, they may be given a token pharmaceutical prescription as a
        placebo until a better diagnosis can be made. In western medicine, the health of the person
        who neither looks nor feels well may be considered, in quantifiable terms, equal to that of
        the person who looks and feels healthy. In this respect, practitioners of traditional
        Chinese medicine (TCM) have a clear diagnostic advantage over their western counterparts.
      </Typography>
      <Typography paragraph align="left">
        To get a better idea of how this is so, please click on the links under the Chinese Medicine
        heading in the toolbar to the left. These will give you an overview of some basic TCM theory
        and other concepts to help you familiarize yourself with the terminology and processes of
        TCM treatments.
      </Typography>
      <Typography  variant="h5"  align="left" >
        A Note on Terminology and Capitalization
      </Typography>
      <Typography paragraph align="left">
        Throughout this site, I use uppercase letters to differentiate the western, biomedical
        concepts of common terms (eg: blood, marrow, kidney function) from the TCM concepts (Blood,
        Marrow, Kidney Qi), which often do not precisely correlate with one another. For instance,
        the Chinese call the viscous red fluid that courses through our arteries and veins
        <i>xue</i>, which translates as "blood," though the TCM practitioner's understanding of what
        that means varies greatly from the western physician's. The two systems of medicine measure
        the quality and quantity of blood differently and recognize in it different purposes and
        characteristics.
      </Typography>
      <Typography paragraph align="left">
        For instance, in TCM the Blood returns to the Liver for storage while we sleep, where among
        other things it is said to "anchor the <i>Hun</i>" (ethereal soul). If the Blood is
        deficient or the Liver dysfunctional, the Hun will be rootless and we will have restless
        sleep, disturbing dreams, and difficulty making decisions and choosing direction in life.
        Western medicine recognizes no such attributes in its understanding of either the blood or
        the liver, let alone acknowledging the existence of a soul.
      </Typography>
      <Typography paragraph align="left">
        To avoid confusion, I use lowercase letters ("blood") when I use a term in the sense of its
        common English usage and uppercase ("Blood") when referring to its concept as it relates to
        TCM theory.
      </Typography>
    </React.Fragment>
  )
}
