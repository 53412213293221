import React from 'react'
import {useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { Link } from '@material-ui/core'
import {cssCodes} from './utils/Theme'

import logo from '../images/logo.gif'
const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: '0px !important',
    paddingLeft: '0px',
    background: '#CCCC99',
    color: '#493118',
    width: '100%'     
  },
  toolbarTitle: {
    flex: 1,
    paddingLeft: '10px'
  },
  toolbarLink: {
    color: cssCodes.fontColor,
    fontSize: '1rem',
    paddingLeft: '0px '
  },
  toolbarTitleMobile: {
    fontWeight: 'bold',
    flex: 1,
    marginLeft: '-30px',
    marginTop: '5px',
    marginBottom: '0px',
    lineHeight: '18px',
    paddingLeft: '10px'
  },
  toolbarLinkMobile: {
    color: cssCodes.fontColor,
    fontSize: '0.8rem',
    textAlign: 'left',
    marginLeft: '-25px',
  },
  address: {
    fontSize: '1rem',
    textAlign: 'right',
    fontWeight: 'bold'
    // padding: '5px',
    // paddingLeft: '10px',
    // marginTop: '10px',
    // marginBottom: '0px',

  }
}))



export default function Header() {
  const classes = useStyles()
  const mobileView = useSelector((state) => state.mobile)

  const displayDesktop = () => {
    return (
    <Toolbar
          className={classes.toolbar}
          style={{ display: 'flex', justifyContent: 'space-between' }}
          disableGutters={true}
        >
          <img itemprop="logo" src={logo} alt="Logo" width="80px" height="80px" style={{marginLeft:'-16px'}}/>
          <Typography
            variant="h5"          
            align="left"
            noWrap
            className={classes.toolbarTitle}
          >
           <span itemprop="name"> Don F Gates</span>
            <br />
            <span style={{ fontSize: '0.8em' }}>Acupuncture &amp; Herbal Medicine</span>
          </Typography>
          <div>
          <Typography  align="right" noWrap className={classes.address}>
            <span itemprop="email">don@eastbaytcm.com</span>
          </Typography>
          <Link href="tel:510-42809430" className={classes.toolbarLink} itemprop="telephone">(510) 428-9430</Link>
          </div>
        </Toolbar>)
  }
  
  const displayMobile = () => {
    return (
    <Toolbar
          className={classes.toolbar}
          style={{ display: 'flex', justifyContent: 'space-between' }}
          disableGutters={true}          
        >
          <div>
          <Typography          
            align="left"
            className={classes.toolbarTitleMobile}
          >
            <span itemprop="name">Don F Gates</span>
            <br />
            <span style={{ fontSize: '0.8em' }}>Acupuncture &amp; Herbal Medicine</span>
          </Typography>
          <Link href="mailto:don@eastbaytcm.com" className={classes.toolbarLinkMobile} itemprop="email">don@eastbaytcm.com</Link><br/>
          <Link href="tel:510-42809430" className={classes.toolbarLinkMobile}  itemprop="telephone">(510) 428-9430</Link>
          </div>
          <img itemprop="logo" src={logo} alt="Logo" width="75px" height="75px" style={{marginRight:'-10px'}}/>
        </Toolbar>)
  }

  return (
    <React.Fragment itemscope
    itemtype="https://schema.org/LocalBusiness">
      {mobileView ? displayMobile() : displayDesktop()}      
    </React.Fragment>
  )
}

Header.propTypes = {
  title: PropTypes.string
}
