import React from 'react'
import Typography from '@material-ui/core/Typography'

import {Link} from "react-router-dom"

export default function Meridians() {

  return (
    <React.Fragment>
      <Typography  variant="h5"  align="left" >
        What are the Meridians?
      </Typography>
      <Typography paragraph align="left">
        In traditional Chinese medical theory, the body is crisscrossed by a network of channels
        through which flow the Qi, Blood, and other material and immaterial substances that sustain
        us. These myriad pathways are known collectively as the <i>jing luo</i>, the channels and
        collaterals, or simply as the meridians.
      </Typography>
      <Typography paragraph align="left">
        The meridians connect the upper to the lower parts of the body, the internal to the external
        aspects, the organs to the vessels to the muscles, tendons, bones, skin, etc. Together, they
        integrate the various parts of the body into an organic whole and provide a network of
        communication, transportation, assistance, and regulation that keeps our systems running
        smoothly.
      </Typography>
      <Typography paragraph align="left">
        There are twelve primary meridians, corresponding to the twelve
        <Link to="/organs">
          <i>zang fu</i>
        </Link>
        organs, and the Qi flows through them (and thus their respective organs) in the following
        order: Lung, Large Intestine, Stomach, Spleen, Heart, Small Intestine, Urinary Bladder,
        Kidney, Pericardium, San Jiao (or "Triple Warmer"), Gall Bladder, and Liver. In addition,
        there are the Eight Extra Meridians&#8212;the Du, Ren, Chong, Dai, Yin Qiao, Yang Qiao, Yin
        Wei, and Yang Wei&#8212;and multitudes of smaller collateral vessels. Of the Eight Extra
        Meridians, only the Du and the Ren have acupoints of their own, running up the midline of
        the back and front of the torso, respectively.
      </Typography>
      <Typography  variant="h5"  align="left" >
        How Do We Use Them?
      </Typography>
      <Typography paragraph align="left">
        Clinically, the meridians provide us with external sites (acupoints) through which we can
        access and affect the Qi of the internal organ systems. For example, the frequently used
        acupoint LI-4 (<i>He Gu</i>) is used to treat all manner of digestive disturbances, as it
        lies along the meridian of the Large Intestine, one of the major organs in the digestive
        system. LI-4 directly affects the Large Intestine organ even though the point's location, in
        the meaty area between the thumb and forefinger, might otherwise seem unrelated. This is
        known as treating distally (needling the hand) to affect local disorders (e.g., abdominal
        pain).
      </Typography>
      <Typography paragraph align="left">
        The path a meridian follows as it runs through the body (as well as the location of the
        points along the meridian) is also used to help diagnose and treat illnesses. For example,
        LI-4 is known to treat disorders as diverse as the common cold, toothache, stuffy nose,
        nosebleed, delayed labor, deafness, mumps, excessive sweating or lack of sweat, neck pain,
        and red-teary-swollen eyes. These are known as channel symptoms and are tied together
        diagnostically by the fact that the Large Intestine meridian runs through each of the areas
        affected.
      </Typography>
      <Typography paragraph align="left">
        Disorders of the meridians themselves can also be used diagnostically, as pain along the
        course of a channel will sometimes indicate dysfunction of its related organ. Again
        reinforcing the clinical usefulness of knowing the meridian system, we also find that
        localized pain can often be treated by needling points farther up or down the meridian.
        Meridian theory is truly one of TCM's strongest advantages over the compartmentalized view
        of the body we inherit from Western medical theory.
      </Typography>
    </React.Fragment>
  )
}
