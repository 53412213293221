import React from 'react'

import { Link, Button, Typography, Grid } from '@material-ui/core'
import { saveAs } from 'file-saver'


const onDownload = async () => {
  saveAs(`${process.env.PUBLIC_URL}/PatientForms.pdf`, "PatientForms.pdf", {type: "application/pdf"})
};


export default function PatientForms() {

  return (
    <React.Fragment>
      <Typography paragraph variant="h5"  align="left" >
        Health-History Forms
      </Typography>

    <Grid
  container
  direction="row"
  justify="flex-start"
  alignItems="flex-start"
  style={{marginBottom: '10px'}}
>
    <Button onClick={onDownload} variant="contained" color="primary">
      Download Forms
    </Button>
    </Grid>
      <Typography paragraph align="left">
        Please click
        <Link onClick={onDownload}>
          Download Forms
        </Link>
        to download my health-history forms. Print them out, fill them out thoroughly, and bring
        them with you to your first treatment. If you have any trouble downloading or printing them,
        call me and I can send you a copy before you come. Otherwise, please show up ten minutes
        before your treatment is due to begin, and you can fill them out at the clinic.
      </Typography>
      
    </React.Fragment>
  )
}
