import React from 'react'
import Typography from '@material-ui/core/Typography'
import {Link} from "react-router-dom"
import { Link as MLink} from '@material-ui/core'


export default function WhatIsQi() {

  return (
    <React.Fragment>
      <Typography  variant="h5"  align="left" >
        What is Qi?
      </Typography>
      <Typography paragraph align="left">
        This is one of the most commonly asked questions about Chinese medicine and, as you'll see,
        is rather difficult to answer. The Chinese concept of Qi (pronounced "chee" and sometimes
        written <i>chi</i>) is not easily translated into English. Variously described as "energy"
        and "life force," it is believed to be the most basic substance of the universe, that of
        which all things are composed. Although it is everywhere fundamentally the same, it's
        capable of changing both in form and in function, wearing a "different hat" in different
        places and assuming different roles. As such, Qi is perhaps better described by what it
        <i>does</i> rather than what it <i>is</i>.
      </Typography>

      <Typography  variant="h5"  align="left" >
        Qi Promotes:
      </Typography>
      <Typography paragraph align="left">
        The promoting, activating function of Qi is seen in the growth and development of our
        bodies, the activities of our internal organs, and the circulation of our Blood and Body
        Fluids. If Qi is weak and the promoting function impaired, our growth will be slowed, our
        organ-function weakened, and our circulation impaired. Thus we can see a malnourished child
        (one who has taken in less postnatal Qi, in the form of nutritious food) will grow to be
        sickly and weak, while a well-nourished child will grow quickly and with strength.
      </Typography>

      <Typography  variant="h5"  align="left" >
        Qi Warms:
      </Typography>
      <Typography paragraph align="left">
        Warming is the function of
        <Link to="/yin_yang">
          Yang Qi
        </Link>
        , and in particular of the Spleen and Kidney Yang. Without Yang Qi, the source of which is
        the Kidneys, the Spleen would lose its ability to transform our meals into Food Qi (Gu Qi)
        and we would become malnourished. People who tend to be cold and suffer from poor digestion
        (loose stools, abdominal bloating, cramping) may be diagnosed with a deficiency of Yang Qi.
      </Typography>

      <Typography  variant="h5"  align="left" >
        Qi Defends:
      </Typography>
      <Typography paragraph align="left">
        Defensive Qi (Wei Qi) guards us from the external pathogenic influences known as Evil Qi
        (Xie Qi), such as the common cold virus, by consolidating the surface of our skin and
        regulating the opening and closing of the pores. In addition, it warms, moistens, and
        partially nourishes the skin and muscles and regulates the body temperature.
      </Typography>

      <Typography  variant="h5"  align="left" >
        Qi Lifts:
      </Typography>
      <Typography paragraph align="left">
        Prolapse, or sinking, of the internal organs is preventing by the lifting power of Spleen
        Qi. If the Spleen Qi is weak, it may lead to prolapse of the uterus, stomach, or anus.
        Hemorrhoids is an example of Qi sinking combined (usually) with Heat and stagnation.
      </Typography>

      <Typography  variant="h5"  align="left" >
        Qi Holds:
      </Typography>
      <Typography paragraph align="left">
        The Qi of the Spleen is also the force that keeps Blood from extravasating (spilling from
        the arteries and veins), while the Qi of the Kidney and Urinary Bladder prevents urinary
        incontinence, wetting the bed, or waking up multiple times to urinate.
      </Typography>

      <Typography  variant="h5"  align="left" >
        Qi Transforms:
      </Typography>
      <Typography paragraph align="left">
        Spleen Qi transforms the food we eat into Food Qi (Gu Qi), some of which is then transformed
        into Blood by Heart Qi; Kidney Qi transforms fluids into useable Body Fluid and excretable
        urine. The Lungs transform the air we breathe into Pectoral Qi (Zong Qi) with the help of
        the Spleen and Kidneys.
      </Typography>

      <Typography  variant="h5"  align="left" >
        Qi Transports:
      </Typography>
      <Typography paragraph align="left">
        Spleen Qi transports Food Qi upward to Heart, where it becomes Blood; Heart Qi transports
        Blood to the rest of the body; Lung Qi transports moisture to the surface of the body and
        its own Qi down to the lower portions of the body. Liver Qi transports Qi in all directions.
      </Typography>

      <Typography  variant="h5"  align="left" >
        Does Qi really exist?
      </Typography>
      <Typography paragraph align="left">
        That's an interesting question and can make for a heated debate, one that constitutes the
        main argument of those who feel they can deny the validity of Chinese medicine. Scientists
        can measure a difference in electrical conductivity on the surface of the skin at acupoints,
        but they cannot yet account for the pathways of the
        <Link to="/meridians">
          meridians
        </Link>
        nor, with any certainty, for the effects of acupuncture or moxibustion on areas distant to
        the points stimulated. (For instance, moxa on the point UB-67, <i>Zhiyin</i>, located beside
        the fifth toenail, is often used successfully to correct a malposition of the fetus, breach
        presentation, prior to childbirth.) Whether or not Qi exists as it was explained by the
        ancients&#8212;an uncertainty that leads TCM researchers and philosophers to use the term
        "TCM <i>theory</i>," rather than "the Laws of TCM"&#8212;the venerable, somewhat
        mystical-sounding-to-Western-ears explanations of the earliest Chinese philosophers still
        serve to guide practitioners of this medicine in directions that prove clinically useful.
        Until a better theory is introduced or (more likely, I believe) the current theories are
        scientifically validated, the ancient theories of Qi, its movements, and its meridians will
        continue to guide us in (and the martial artists, and meditators, and massage therapists)
        our practices.
      </Typography>

      <Typography paragraph align="left">
        The argument is far from resolved and will likely remain so for some time, perhaps
        eternally. For two interesting perspectives on the debate, both from acupuncturists, have a
        look at pulsemed.org's article
        <MLink href="http://www.pulsemed.org/moreqicontroversy.htm" target="_blank">
          Opposing Viewpoints on Qi
        </MLink>
        .
      </Typography>
    </React.Fragment>
  )
}
