import React from 'react'
import Typography from '@material-ui/core/Typography'

import { Link } from '@material-ui/core'

export default function Testimonials() {
  return (
    <React.Fragment>
      <Typography paragraph align="left">
        I am honored and humbled by the trust and confidence my patients have invested in me over
        the years, and my thanks go out to those who have chosen to share their feelings and
        experiences here, for the benefit of others. I am truly grateful for their efforts. If you'd
        like to express your thoughts in this forum, please feel free to send me an email at
        <b style={{marginLeft:'5px'}}>don@eastbaytcm.com</b>.
      </Typography>
      <Link href="http://www.yelp.com/biz/don-gates-lac-oakland" target="_blank">
        <img
          src="https://images.yelp.com/extimg/fsc/TmfCOd_rhfZfUk91xwy-vw.png"
          width="125"
          height="54"
          alt="Don Gates, LAc"
        />
      </Link>

      <Typography  variant="h5"  align="left" >
        Patient Testimonials
      </Typography>
      <Typography paragraph align="left">
        "Don is an eighteenth-century Chinese monk reborn in the body of a post-punk neo-beatnik
        East Bay bohemian. He dispatches illness and imbalance with the savage grace of a Kung Fu
        master taking on the Dallas Cowboys. He has all the qualities I could ask for in an
        acupuncturist: he is patient and calm, focused and methodical, perceptive and compassionate,
        and his sense of humor is at least as sharp as his needles."
        <span className="author">&#8212; HD, San Francisco</span>
      </Typography>
      <Typography paragraph align="left">
        "I have been struggling to respond to your kindness yesterday. It seems very cheep to just
        say thank you for your actions, But I do thank you. I hope you have seen the progression of
        myself since my first visit. I came to you a scared, desperate, red faced, over heated,
        "western medicine" individual willing to do about anything to find relief. I left your
        office yesterday, not only a 1000% better than when I started with you, but more confident,
        and full of hope for the future, and for that I expect you to accept a heartfelt Thank You!
        No, I am not pain free, or drug free, but my pain level is way less, my drug use
        (prescription, in case I want to run for president :-)) is far less, and I face the future
        with hope, and something that has been missing, happiness. You have been a blessing in my
        life, and although my family has not met you, they thank you as well."
        <span className="author">&#8212; DM, Castro Valley</span>
      </Typography>
      <Typography paragraph align="left">
        "I just wanted to give a sincere thank you for all of your generosity over the last couple
        months with my treatment. I really appreciate your consideration and support and hope I can
        repay you someday. I also appreciate your taking the extra steps to consult with your
        colleagues and friends for other ideas and insights that may be of help. It shows that you
        really care and want to provide the best service you can. There are not many who are willing
        and interested enough to look outside of their current body of expertise, to see if there is
        more to be learned and incorporated into their healing efforts. You are a very kind,
        generous and dedicated practitioner and it does not go unnoticed. Thank you so much again
        for all of your efforts and I look forward to seeing you next week."
        <span className="author">&#8212; AC, Oakland</span>
      </Typography>
      <Typography paragraph align="left">
        "I've had lower back issues for a decade, and when the usual chiropractic work wasn't
        alleviating one nagging bout of back pain, I went to see Don. Don's treatment was not only
        extremely effective, but instantaneous AND long-lasting (a nice combination)."
        <span className="author">&#8212; KS, Oakland</span>
      </Typography>
      <Typography paragraph align="left">
        "The effect of the acupuncture seems to have a time-release effect on me. As the day
        continued, I seemed to feel more and more relaxed. This went on for about two or more days.
        I have been doing great this whole week. My neck has a few knots in it now, but that's from
        the computer and grading. It's much better than usual. I have been sleeping better, been
        more regular, and felt more relaxed.""
        <span className="author">&#8212; SU, Hayward</span>
      </Typography>
      <Typography align="left">
        "Yesterday's session was intense. It's amazing to me how profoundly acupuncture influences
        mind and body ... the myriad of sensations I had on that table yesterday were so interesting
        to observe. I felt really happy and almost giggly afterwards, and this feeling persisted for
        a while. I could have fallen asleep after the needling on your table."
      </Typography>
      <Typography paragraph align="left">
        <i>(Then, a few days later)</i>
        "I think I still feel the benefit of the last treatment. I have been sleeping well, and once
        again the treatment has put me back in my groove, so to speak. Overall perhaps the most
        profound acupuncture treatment thus far. I will be lucky to find someone half as good as you
        in Berlin."
        <span className="author">&#8212; BK, Oakland</span>
      </Typography>
      <Typography paragraph align="left">
        "I can't thank you enough for your words the other morning. Your encouragement inspired me
        to be diligent in getting nearly the whole bottle of herbs into my son (no easy task!!!). He
        had a quiet, long night of sleep and we haven't heard another complaint about the ears. No
        antibiotics needed! Again, I can't thank you enough for your support and wisdom."
        <span className="author">&#8212; MW, Walnut Creek</span>
      </Typography>
      <Typography paragraph align="left">
        The treatment was just what I needed, and as always you did a fabulous job knowing what
        areas to focus on. I felt great when I left, and I still do. Thank you!!!!
        <span className="author">&#8212; KBT, Oakland</span>
      </Typography>
      <Typography paragraph align="left">
        "I am always blessed by who you are and inspired by the breadth of your intention with so
        many beings up against a physical hurdle I never imagined could step into my life. I cannot
        tell you how much you have healed my body and soul and with such grace."
        <span className="author">&#8212; SM, Castro Valley</span>
      </Typography>
      <Typography paragraph align="left">
        "The herbs have been great. Last cycle was near normal with seven moderate days. I can't
        tell you how fantastic it is to be able to be up and about and have energy for the family.
        THANK YOU!!!!!"
        <span className="author">&#8212; HJ, Cambridge, MA</span>
      </Typography>
      <Typography paragraph align="left">
        "I wanted to write you and say a huge THANK YOU for your most kind gift of acupuncture. That
        was just so incredibly thoughtful and generous of you and I appreciate it so so much. It
        helped a lot. I have been feeling much more stable and grounded and less overwhelmed by the
        grief and sadness. Wow, I was really amazed by how much it helped. Also, it helped my back a
        lot. Words cannot thank you enough, Don - it really felt like an angel was looking out for
        me. I probably wouldn't have thought of acupuncture for the grief, so I thank you for it."
        <span className="author">&#8212; LP, Oakland</span>
      </Typography>
      <Typography paragraph align="left">
        "In regards to the acupuncture treatment I received, I loved it as I not only felt fine when
        I got on my flight, but I managed to sleep through most of it. The moxa stick was great, and
        we got a big laugh out of what it probably looked like when it went through the x-ray
        machines in my carry on bags. That being said, I consider you to be my new acupuncturist. I
        appreciate that you were able to see me on such short notice and got me the results I was
        looking for."
        <span className="author">&#8212; RO, Alameda</span>
      </Typography>
      <Typography paragraph align="left">
        "I have been a patient of Don Gates for several years now, beginning when he was in
        acupuncture school in San Francisco. I see him once a week for stress reduction and pain
        relief (shoulders, neck, and back). He was very, very good when he was in school, attending
        to all my needs then. But he is excellent now, with good results happening within hours. His
        clinical approach is one of compassion, thorough examination, efficient use of needles, and
        peaceful environment. He works very hard at focusing on each patient, even on the busiest of
        days. He begins my appointments in peace, and finishes each one the same. That leaves me in
        a very good mental state when I leave to drive back across the bay (I travel 45 minutes to
        see him, and he is worth it). I give him the highest of recommendations."
        <span className="author">&#8212; JE, San Mateo</span>
      </Typography>
      <Typography align="left">
        "I got a message from my doctor this morning telling me that my ultrasound results look
        great and that the cyst seems to have resolved! Can you believe it?!!!! AMAZING! I am
        eternally grateful to you and your work ,and I know it is all thanks to you!"
      </Typography>
      <Typography paragraph align="left">
        <i>(and later that year)</i>
        "You are a miracle worker, as always! I woke up this morning, very cautiously getting up,
        and realized I was in no pain at all! After sitting in the office chair for a while I
        started to feel it be a little sore....but nothing like pain or anything. And once I walk
        around a bit, I feel nothing again! I will continue to use the secret monk potion! But thank
        you SOOOO much! I feel like Im walking on clouds compared to yesterday! Thank you!"
        <span className="author">&#8212; JL, Oakland</span>
      </Typography>
      <Typography paragraph align="left">
        "My thanks to you, Dr. Gates, for taking the time to learn about me (during the long
        health-history session) before you started treating me. My experience has shown that this
        trait is very rare within the medical profession. I appreciate your interest and
        thoughtfulness, and I continue to be grateful for your concern in treating me as a whole
        rather than as a collection of symptoms. The acupuncture and herbal medicine you prescribed
        have definitely alleviated my tremors, fatigue and menopausal symptoms. It is important to
        note that you are vigilant and modify my treatments as I progress."
        <span className="author">&#8212; DP, South Pasadena</span>
      </Typography>
      <Typography paragraph align="left">
        "A session with Don is relaxing, yet stimulating. He is calm and professional and totally
        present with his patients. This spring I became sick for two months with a flu-like chest
        cough. After multiple trips to Kaiser I went to see Don. I felt noticeably better after one
        dose of herbs and one acupuncture session, and was 100% better after one week."
        <span className="author">&#8212; KC, Vallejo</span>
      </Typography>
      <Typography paragraph align="left">
        "Strong stuff! A good drug-free alternative for my ever-clogged sinuses. I hate taking
        allergy medicine, as it makes me feel drowsy and/or wired, so Don's treatment was an easy
        and quick way to clear my sorry sinuses. He's also helps me whenever I feel depressed or
        generally run-down, with sound advice on easy food or herbal remedies, as well as a quick
        pick me up needle which left me feeling calm and alert."
        <span className="author">&#8212; NB, San Francisco</span>
      </Typography>
      <Typography paragraph align="left">
        "Whether I come in full-blown sick or just a little imbalanced, I always leave a session
        with Don feeling relaxed yet energetically fortified. I always seem to have a little
        something out of whack inside my body, yet Don has never once made me feel like any problem
        is too weird or too small. He is so great at being completely present and attentive, and I
        feel this sense of teamwork with him when it comes to working toward an optimal health."
        <span className="author">&#8212; SD, San Francisco</span>
      </Typography>
      <Typography paragraph align="left">
        "After abdominal surgery five years ago, I developed a condition where my intestines can
        kink up like a garden hose, causing intestinal obstructions. These are incredibly painful
        and very dangerous. The western approach says there is nothing that can be done to prevent
        these episodes &#8212; if they happen, I should check into the hospital for a very painful
        naso-gastric tube for 3 days, or surgery, which will make the problem worse. Don, on the
        other hand, can treat me with needles, moxa, and herbs, immediately relieving my pain and
        shortly resolving the problem, with no trip to the hospital required. In addition, he has
        advised me on dietary changes to avoid the problem in the future. Just knowing that I have
        Don on my team to help avoid this terrible condition helps me to relax and not worry about
        it, which in turn keeps it from happening as often."
        <span className="author">&#8212; TC, Oakland</span>
      </Typography>
      <Typography paragraph align="left">
        "Being somewhat of a cynic, I'm constantly amazed by some of the experiences I've had with
        TCM and particularly acupuncture over the last few years. My first visit compounded my
        astonishment when I found the acupuncturist could affect one part of the body by needling a
        spot completely unrelated to the area of my complaint. Voodoo, I thought. My first visit was
        as much out of curiosity as it was in hopes of getting relief from a minor sinus condition.
        When the doctor opened my sinuses with a quick needling session centered around my ankle I
        thought jeez this is magic. Well, after several years and treatments for many maladies
        including a recent bout with lung cancer [including surgery] I'm still alive and well. I am
        a devout believer in Acupuncture and especially the talents of this young man. By the way,
        did I mention that he's my son?"
        <span className="author">&#8212; DG, Balboa Island</span>
      </Typography>
    </React.Fragment>
  )
}
