import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Link } from '@material-ui/core'

export default function HerbalMedicine() {
  return (
    <React.Fragment>
      <Typography  variant="h5"  align="left" >
        Chinese Herbal Medicine
      </Typography>
      <Typography paragraph align="left">
        Herbal medicine is one of the great strengths of TCM and remains an integral part of its
        practice. As with acupuncture, the art and science behind it boasts a history of thousands
        of years of refinement and cultivation. Over the ages, countless herbalists have dedicated
        their lives to researching and classifying thousands of herbs, leaving us their extensive
        writings (our <i>materia medica</i>) to work with. Thanks to their efforts, modern
        practitioners are able to select herbs and traditional formulas that have proven safe and
        effective for generations of patients and to then customize these traditional formulas for
        our patients at each office visit, based on their most current symptoms.
      </Typography>
      <Typography paragraph align="left">
        As an example, a patient may seek treatment for the problem of menstrual cramps. The common
        diagnosis of Liver Qi and Blood stagnation may well be deemed to be the root cause of her
        problems, but rather than reach for Xiao Yao San, the most common formula to treat that
        diagnosis, a good herbalist will refrain from making judgments until a full health-history
        has been taken and a true diagnosis reached. Even if Xiao Yao San turns out to be the proper
        base formula for the patient, it will likely have to be modified (herbs added and/or
        removed) to be perfectly suited for her condition at that time. And the next patients who
        walks through the door, though she may complain of the "same" menstrual cramps, may well
        have a different diagnosis entirely. One woman may have a robust constitution while the
        other seems more fragile. One woman may be too hot (Yin deficient or excess Heat), the other
        too cold (Yang deficient or excess Cold). Giving both women the same prescription would not
        only be less than ideal, it may in fact be counterproductive for one of them.
      </Typography>

      <Typography  variant="h5"  align="left" >
        Customizing Formulas
      </Typography>
      <Typography paragraph align="left">
        A custom herbal formula rarely remains the same from visit to visit. As a patient's
        presentation changes, the prescription must also be modified accordingly. For example, if a
        patient with menstrual cramps also complains of poor digestion, the first few bags of herbs
        may treat both sets of symptoms but focus more on improving Spleen function (to help the
        patient to better digest the formula). After digestive symptoms improve, the emphasis of the
        formula can be shifted more to addressing her cramps. The prescription will be modified
        again as the cramps cease to be a problem.
      </Typography>
      <Typography paragraph align="left">
        Thus, customizing a formula&#8212;the addition or subtraction of just a single herb can have
        a significant impact&#8212;is essential to obtaining maximum therapeutic results. This is
        one reason why there are very few prescriptions that are taken long-term without
        modification.
      </Typography>

      <Typography  variant="h5"  align="left" >
        What Type of Herbs Do You Use?
      </Typography>
      <Typography paragraph align="left">
        In my clinic I dispense herbs in concentrated-granule (powder) and pill form. While a
        decoction of raw herbs is thought by some to be stronger than the pills and powders, patient
        compliance with this method is generally low and thus the overall effect is compromised. For
        most situations, I have found powders and pills to be just as effective as decoctions. If I
        think a patient would benefit more from drinking a raw-herb decoction (and the patient will
        commit to the time and labor involved in preparing their herbs), I work with several herbal
        pharmacies in the Oakland and San Francisco "Chinatown" neighborhoods that stock a complete
        line of raw herbs. These prescriptions can be picked up in person or mailed to the patient
        directly.
      </Typography>

      <Typography  variant="h5"  align="left" >
        Are Herbs Safe?
      </Typography>
      <Typography paragraph align="left">
        Herbal medicines are extremely safe when prescribed by a knowledgeable herbalist. There are
        circumstances wherein certain herbs should not be taken, for example during pregnancy or
        when breast-feeding. Long-term use or large doses of some herbs can also cause problems. For
        this reason, it is important to take herbs only under the direction of an experienced
        licensed herbalist. Clearly, it's equally important not to share your herbs with others
        unless under the supervision of an herbalist.
      </Typography>

      <Typography  variant="h5"  align="left" >
        Herb-Drug Interactions
      </Typography>
      <Typography paragraph align="left">
        Doctor John Chen (PhD, PharmD, OMD, LAc)&#8212;an authority on western pharmacology as well
        as Chinese herbal medicine&#8212;has written on the subject of the rising awareness of
        possible interactions between herbal medicine and pharmaceuticals. If you're curious, the
        full text of his article can be found
        <Link href="http://www.acupuncture.com/herbs/drugherb.htm" target="_blank">
          here
        </Link>
        .
      </Typography>
    </React.Fragment>
  )
}
