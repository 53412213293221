import React from 'react'
import Typography from '@material-ui/core/Typography'

export default function Privacy() {
  return (
    <React.Fragment>
      <Typography  variant="h5"  align="left" >
        Privacy Policy
      </Typography>
      <Typography paragraph align="left">
        This notice describes how medical information about you may be used and disclosed and how
        you can get access to this information. Please read it carefully.
      </Typography>
      <Typography  variant="h6"  align="left" >
        Notice of Privacy Policy
      </Typography>
      <Typography paragraph align="left">
        The following is the privacy policy ("Privacy Policy") of Don F. Gates, L.Ac. ("Covered
        Entity") as described in the Health Insurance Portability and Accountability Act of 1996 and
        regulations promulgated thereunder, commonly known as HIPAA. HIPAA requires Covered Entity
        by law to maintain the privacy of your personal health information and to provide you with
        notice of Covered Entity's legal duties and privacy policies with respect to your personal
        health information. We are required by law to abide by the terms of this Privacy Notice.
      </Typography>
      <Typography  variant="h6"  align="left" >
        Your Personal Health Information
      </Typography>
      <Typography paragraph align="left">
        We collect personal health information from you through treatment, payment, and related
        healthcare operations, the application and enrollment process, and/or healthcare providers
        or health plans, or through other means, as applicable. Your personal health information
        that is protected by law broadly includes any information, oral, written, or recorded, that
        is created or received by certain healthcare entities, including healthcare providers, such
        as physicians and hospitals, as well as, health insurance companies or plans. The law
        specifically protects health information that contains data, such as your name, address,
        Social Security Number, and others, that could be used to identify you as the individual
        patient who is associated with that health information.
      </Typography>
      <Typography  variant="h6"  align="left" >
        Uses or Disclosures of Your Personal Health Information
      </Typography>
      <Typography paragraph align="left">
        Generally, we may not use or disclose your personal health information without your
        permission. Further, once your permission has been obtained, we must use or disclose your
        personal health information in accordance with the specific terms that permission. The
        following are the circumstances under which we are permitted by law to use or disclose your
        personal health information.
      </Typography>

      <Typography  variant="h6"  align="left" >
        Without Your Consent
      </Typography>
      <Typography paragraph align="left">
        Without your consent, we may use or disclose your personal health information in order to
        provide you with services and the treatment you require or request, or to collect payment
        for those services, and to conduct other related healthcare operations otherwise permitted
        or required by law. Also, we are permitted to disclose your personal health information
        within and among our workforce in order to accomplish these same purposes. However, even
        with your permission, we are still required to limit such uses or disclosures to the minimal
        amount of personal health information that is reasonably required to provide those services
        or complete those activities.
      </Typography>
      <Typography align="left">
        Examples of treatment activities include:
      </Typography>
        <ol type="a">
          <li>
            the provision, coordination, or management of healthcare and related services by
            healthcare providers;
          </li>
          <li>consultation between healthcare providers relating to a patient; or </li>
          <li>the referral of a patient for healthcare from one healthcare provider to another.</li>
        </ol>
      <Typography align="left">
        Examples of payment activities include:
      </Typography>
        <ol type="a">
          <li>billing and collection activities and related data processing</li>
          <li>
            actions by a health plan or insurer to obtain premiums or to determine or fulfill its
            responsibilities for coverage and provision of benefits under its health plan or
            insurance agreement, determinations of eligibility or coverage, adjudication or
            subrogation of health benefit claims;
          </li>
          <li>
            medical necessity and appropriateness of care reviews, utilization review activities;
            and
          </li>
          <li>
            disclosure to consumer reporting agencies of information relating to collection of
            premiums or reimbursement.
          </li>
        </ol>
      <Typography align="left">
        Examples of healthcare operations include:
      </Typography>
        <ol type="a">
          <li>development of clinical guidelines; </li>
          <li>
            contacting patients with information about treatment alternatives or communications in
            connection with case management or care coordination;
          </li>
          <li>reviewing the qualifications of and training healthcare professionals; </li>
          <li>underwriting and premium rating; </li>
          <li>medical review, legal services, and auditing functions; and </li>
          <li>general administrative activities such as customer service and data analysis.</li>
        </ol>
      <Typography  variant="h6"  align="left" >
        As Required By Law
      </Typography>
      <Typography align="left">
        We may use or disclose your personal health information to the extent that such use or
        disclosure is required by law and the use or disclosure complies with and is limited to the
        relevant requirements of such law. Examples of instances in which we are required to
        disclose your personal health information include:
      </Typography>
        <ol type="a">
          <li>
            public health activities including preventing or controlling disease or other injury,
            public health surveillance or investigations, reporting adverse events with respect to
            food or dietary supplements or product defects or problems to the Food and Drug
            Administration, medical surveillance of the workplace or to evaluate whether the
            individual has a work-related illness or injury in order to comply with federal or state
            law;
          </li>
          <li>
            disclosures regarding victims of abuse, neglect, or domestic violence including
            reporting to social service or protective services agencies;
          </li>
          <li>
            health oversight activities including, audits, civil, administrative, or criminal
            investigations, inspections, licensure or disciplinary actions, or civil,
            administrative, or criminal proceedings or actions, or other activities necessary for
            appropriate oversight of government benefit programs;
          </li>
          <li>
            judicial and administrative proceedings in response to an order of a court or
            administrative tribunal, a warrant, subpoena, discovery request, or other lawful
            process;
          </li>
          <li>
            law enforcement purposes for the purpose of identifying or locating a suspect, fugitive,
            material witness, or missing person, or reporting crimes in emergencies, or reporting a
            death;
          </li>
          <li>
            disclosures about decedents for purposes of cadaveric donation of organs, eyes or
            tissue;
          </li>
          <li>for research purposes under certain conditions;</li>
          <li>to avert a serious threat to health or safety;</li>
          <li>military and veterans activities;</li>
          <li>
            national security and intelligence activities, protective services of the President and
            others;
          </li>
          <li>
            medical suitability determinations by entities that are components of the Department of
            State;
          </li>
          <li>correctional institutions and other law enforcement custodial situations;</li>
          <li>
            covered entities that are government programs providing public benefits, and for
            workers' compensation.
          </li>
        </ol>

      <Typography  variant="h6"  align="left" >
        All Other Situations, With Your Specific Authorization
      </Typography>
      <Typography paragraph align="left">
        Except as otherwise permitted or required, as described above, we may not use or disclose
        your personal health information without your written authorization. Further, we are
        required to use or disclose your personal health information consistent with the terms of
        your authorization. You may revoke your authorization to use or disclose any personal health
        information at any time, except to the extent that we have taken action in reliance on such
        authorization, or, if you provided the authorization as a condition of obtaining insurance
        coverage, other law provides the insurer with the right to contest a claim under the policy.
      </Typography>

      <Typography  variant="h6"  align="left" >
        Miscellaneous Activities, Notice
      </Typography>
      <Typography paragraph align="left">
        We may contact you to provide appointment reminders or information about treatment
        alternatives or other health-related benefits and services that may be of interest to you.
        We may contact you to raise funds for Covered Entity.
      </Typography>

      <Typography  variant="h6"  align="left" >
        Your Rights With Respect to Your Personal Health Information
      </Typography>
      <Typography paragraph align="left">
        Under HIPAA, you have certain rights with respect to your personal health information. The
        following is a brief overview of your rights and our duties with respect to enforcing those
        rights.
      </Typography>

      <Typography  variant="h6"  align="left" >
        Right To Request Restrictions On Use Or Disclosure
      </Typography>
      <Typography align="left">
        You have the right to request restrictions on certain uses and disclosures of your personal
        health information about yourself. You may request restrictions on the following uses or
        disclosures:
      </Typography>
        <ol type="a">
          <li>to carry out treatment, payment, or healthcare operations;</li>
          <li>
            disclosures to family members, relatives, or close personal friends of personal health
            information directly relevant to your care or payment related to your healthcare, or
            your location, general condition, or death;
          </li>
          <li>
            instances in which you are not present or your permission cannot practicably be obtained
            due to your incapacity or an emergency circumstance;
          </li>
          <li>
            permitting other persons to act on your behalf to pick up filled prescriptions, medical
            supplies, X-rays, or other similar forms of personal health information; or
          </li>
          <li>
            disclosure to a public or private entity authorized by law or by its charter to assist
            in disaster relief efforts.
          </li>
        </ol>
      <Typography paragraph align="left">
        While we are not required to agree to any requested restriction, if we agree to a
        restriction, we are bound not to use or disclose your personal healthcare information in
        violation of such restriction, except in certain emergency situations. We will not accept a
        request to restrict uses or disclosures that are otherwise required by law.
      </Typography>

      <Typography  variant="h6"  align="left" >
        Right To Receive Confidential Communications
      </Typography>
      <Typography paragraph align="left">
        You have the right to receive confidential communications of your personal health
        information. We may require written requests. We may condition the provision of confidential
        communications on you providing us with information as to how payment will be handled and
        specification of an alternative address or other method of contact. We may require that a
        request contain a statement that disclosure of all or a part of the information to which the
        request pertains could endanger you. We may not require you to provide an explanation of the
        basis for your request as a condition of providing communications to you on a confidential
        basis. We must permit you to request and must accommodate reasonable requests by you to
        receive communications of personal health information from us by alternative means or at
        alternative locations. If we are a healthcare plan, we must permit you to request and must
        accommodate reasonable requests by you to receive communications of personal health
        information from us by alternative means or at alternative locations if you clearly state
        that the disclosure of all or part of that information could endanger you.
      </Typography>

      <Typography  variant="h6"  align="left" >
        Right To Inspect And Copy Your Personal Health Information
      </Typography>
      <Typography align="left">
        Your designated record set is a group of records we maintain that includes Medical records
        and billing records about you, or enrollment, payment, claims adjudication, and case or
        medical management records systems, as applicable. You have the right of access in order to
        inspect and obtain a copy your personal health information contained in your designated
        record set, except for
      </Typography>
        <ol type="a">
          <li>psychotherapy notes</li>
          <li>
            information complied in reasonable anticipation of, or for use in, a civil, criminal, or
            administrative action or proceeding, and
          </li>
          <li>
            health information maintained by us to the extent to which the provision of access to
            you would be prohibited by law.
          </li>
        </ol>
        <Typography paragraph align="left">
        We may require written requests. We must provide you with access to your personal health
        information in the form or format requested by you, if it is readily producible in such form
        or format, or, if not, in a readable hard copy form or such other form or format. We may
        provide you with a summary of the personal health information requested, in lieu of
        providing access to the personal health information or may provide an explanation of the
        personal health information to which access has been provided, if you agree in advance to
        such a summary or explanation and agree to the fees imposed for such summary or explanation.
        We will provide you with access as requested in a timely manner, including arranging with
        you a convenient time and place to inspect or obtain copies of your personal health
        information or mailing a copy to you at your request. We will discuss the scope, format, and
        other aspects of your request for access as necessary to facilitate timely access. If you
        request a copy of your personal health information or agree to a summary or explanation of
        such information, we may charge a reasonable cost-based fee for copying, postage, if you
        request a mailing, and the costs of preparing an explanation or summary as agreed upon in
        advance. We reserve the right to deny you access to and copies of certain personal health
        information as permitted or required by law. We will reasonably attempt to accommodate any
        request for personal health information by, to the extent possible, giving you access to
        other personal health information after excluding the information as to which we have a
        ground to deny access. Upon denial of a request for access or request for information, we
        will provide you with a written denial specifying the legal basis for denial, a statement of
        your rights, and a description of how you may file a complaint with us. If we do not
        maintain the information that is the subject of your request for access but we know where
        the requested information is maintained, we will inform you of where to direct your request
        for access.
      </Typography>
      <Typography  variant="h6"  align="left" >
        Right To Amend Your Personal Health Information
      </Typography>
      <Typography align="left">
        You have the right to request that we amend your personal health information or a record
        about you contained in your designated record set, for as long as the designated record set
        is maintained by us. We have the right to deny your request for amendment, if:
      </Typography>
        <ol type="a">
          <li>
            we determine that the information or record that is the subject of the request was not
            created by us, unless you provide a reasonable basis to believe that the originator of
            the information is no longer available to act on the requested amendment,
          </li>
          <li>the information is not part of your designated record set maintained by us, </li>
          <li>the information is prohibited from inspection by law, or</li>
          <li>the information is accurate and complete.</li>
        </ol>
        <Typography paragraph align="left">
        We may require that you submit written requests and provide a reason to support the
        requested amendment. If we deny your request, we will provide you with a written denial
        stating the basis of the denial, your right to submit a written statement disagreeing with
        the denial, and a description of how you may file a complaint with us or the Secretary of
        the U.S. Department of Health and Human Services ("DHHS"). This denial will also include a
        notice that if you do not submit a statement of disagreement, you may request that we
        include your request for amendment and the denial with any future disclosures of your
        personal health information that is the subject of the requested amendment. Copies of all
        requests, denials, and statements of disagreement will be included in your designated record
        set. If we accept your request for amendment, we will make reasonable efforts to inform and
        provide the amendment within a reasonable time to persons identified by you as having
        received personal health information of yours prior to amendment and persons that we know
        have the personal health information that is the subject of the amendment and that may have
        relied, or could foreseeably rely, on such information to your detriment. All requests for
        amendment shall be sent to Don F. Gates, L.Ac. c/o Grand Acupuncture Center, 3931 Grand Ave,
        2nd Floor, Oakland, CA 94610.
      </Typography>
      <Typography  variant="h6"  align="left" >
        Right To Receive An Accounting Of Disclosures Of Your Personal Health Information
      </Typography>
      <Typography align="left">
        You have the right to receive a written accounting of all disclosures of your personal
        health information that we have made within the six (6) year period immediately preceding
        the date on which the accounting is requested. You may request an accounting of disclosures
        for a period of time less than six (6) years from the date of the request. Such disclosures
        will include the date of each disclosure, the name and, if known, the address of the entity
        or person who received the information, a brief description of the information disclosed,
        and a brief statement of the purpose and basis of the disclosure or, in lieu of such
        statement, a copy of your written authorization or written request for disclosure pertaining
        to such information. We are not required to provide accountings of disclosures for the
        following purposes:
      </Typography>
        <ol type="a">
          <li>treatment, payment, and healthcare operations,</li>
          <li>disclosures pursuant to your authorization,</li>
          <li>disclosures to you,</li>
          <li>for a facility directory or to persons involved in your care, </li>
          <li>for national security or intelligence purposes, and</li>
          <li>to correctional institutions.</li>
        </ol>
        <Typography paragraph align="left">
        We reserve our right to temporarily suspend your right to receive an accounting of
        disclosures to health oversight agencies or law enforcement officials, as required by law.
        We will provide the first accounting to you in any twelve (12) month period without charge,
        but will impose a reasonable cost-based fee for responding to each subsequent request for
        accounting within that same twelve (12) month period. All requests for an accounting shall
        be sent to Don F. Gates, L.Ac. c/o Grand Acupuncture Center, 3931 Grand Ave, 2nd Floor,
        Oakland, CA 94610.
      </Typography>
      <Typography  variant="h6"  align="left" >
        Complaints
      </Typography>
      <Typography paragraph align="left">
        You may file a complaint with us and with the Secretary of DHHS if you believe that your
        privacy rights have been violated. You may submit your complaint in writing by mail or
        electronically to Don F. Gates, L.Ac. c/o Grand Acupuncture Center, 3931 Grand Ave, 2nd
        Floor, Oakland, CA 94610. A complaint must name the entity that is the subject of the
        complaint and describe the acts or omissions believed to be in violation of the applicable
        requirements of HIPAA or this Privacy Policy. A complaint must be received by us or filed
        with the Secretary of DHHS within 180 days of when you knew or should have known that the
        act or omission complained of occurred. You will not be retaliated against for filing any
        complaint.
      </Typography>
      <Typography  variant="h6"  align="left" >
        Amendments to this Privacy Policy
      </Typography>
      <Typography paragraph align="left">
        We reserve the right to revise or amend this Privacy Policy at any time. These revisions or
        amendments may be made effective for all personal health information we maintain even if
        created or received prior to the effective date of the revision or amendment. We will
        provide you with notice of any revisions or amendments to this Privacy Policy, or changes in
        the law affecting this Privacy Notice, by mail or electronically within 60 days of the
        effective date of such revision, amendment, or change.
      </Typography>
      <Typography  variant="h6"  align="left" >
        On-Going Access to Privacy Policy
      </Typography>
      <Typography paragraph align="left">
        We will provide you with a copy of the most recent version of this Privacy Policy at any
        time upon your written request sent to Don F. Gates, L.Ac. c/o Grand Acupuncture Center,
        3931 Grand Ave, 2nd Floor, Oakland, CA 94610. For any other requests or for further
        information regarding the privacy of your personal health information, and for information
        regarding the filing of a complaint with us, please contact our privacy officer at the
        address listed above.
      </Typography>
    </React.Fragment>
  )
}
