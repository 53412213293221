import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import buildStore from './app/store'
import { Provider } from 'react-redux'
import { Theme } from './components/utils/Theme'
import reportWebVitals from './reportWebVitals'

const reduxStore = buildStore()

ReactDOM.render(
  <React.StrictMode>
    <Provider store={reduxStore}>
      <Theme>
        <App />
      </Theme>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
