import React, {useState} from 'react'
import {useSelector} from 'react-redux'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link as RouterLink
} from "react-router-dom";
import Analytics from 'react-router-ga';

import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import Link from '@material-ui/core/Link'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import MenuIcon from '@material-ui/icons/Menu'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Header from './Header'

import Home from '../pageContent/Home'
import Directions from '../pageContent/Directions'
import OurClinic from '../pageContent/my_practice/OurClinic'
import YourFirstVisit from '../pageContent/my_practice/YourFirstVisit'
import FeesAndBilling from '../pageContent/my_practice/FeesAndBilling'
import PatientForms from '../pageContent/my_practice/PatientForms'
import Testimonials from '../pageContent/my_practice/Testimonials'
import Privacy from '../pageContent/my_practice/Privacy'
import MyBackground from '../pageContent/about_me/MyBackground'
import WhyPractice from '../pageContent/about_me/WhyPractice'
import MyLogo from '../pageContent/about_me/MyLogo'
import AllAboutTCM from '../pageContent/chinese_medicine/AllAboutTCM'
import Acupuncture from '../pageContent/chinese_medicine/Acupuncture'
import HerbalMedicine from '../pageContent/chinese_medicine/HerbalMedicine'
import WhatIsQi from '../pageContent/chinese_medicine/WhatIsQi'
import QiSensation from '../pageContent/chinese_medicine/QiSensation'
import Meridians from '../pageContent/chinese_medicine/Meridians'
import Organs from '../pageContent/chinese_medicine/Organs'
import YinYang from '../pageContent/chinese_medicine/YinYang'
import Links from '../pageContent/chinese_medicine/Links'

const drawerWidth = 210

const reload = () => window.location.reload()

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    }
  },
  menuButton: {
    color:"#493118",
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    marginTop: '30px'
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  listLink: {
    color: '#493118'
  }
}))

function ResponsiveDrawer(props) {

  const { window } = props
  const classes = useStyles()
  const theme = useTheme()
  const mobileView = useSelector((state) => state.mobile)

  const [menuOpen, setMenuOpen] = useState(false)


  const handleDrawerToggle = () => {
    setMenuOpen(!menuOpen)
  }

  const handleMenuClick = () => {
    if (mobileView) {
      handleDrawerToggle()
    }
  }
  
  const myPracticeMenu = [
    ['Directions', 'directions'],
    ['Our Clinic', 'our_clinic'],
    ['Your First Visit', 'your_first_visit'],
    ['Fees & Billing', 'fees_billing'],
    ['Patient Forms', 'patient_forms'],
    ['Testimonials', 'testimonials'],
    ['Privacy', 'privacy']
  ]
  const aboutMeMenu = [
    ['My Background', 'my_background'],
    ['Why I Practice TCM', 'why_i_practice'],
    ['My Logo', 'my_logo']
  ]
  
  const chineseMedicineMenu = [
    ['All About TCM', 'all_about_tcm'],
    ['Acupuncture', 'acupuncture'],
    ['Herbal Medicine', 'herbal_medicine'],
    ['What is Qi?', 'what_is_qi'],
    ['The Qi Sensation', 'qi_sensation'],
    ['The Meridians', 'meridians'],
    ['The Organs', 'organs'],
    ['Yin & Yang', 'yin_yang'],
    ['Links', 'links']
  ]

  const drawer = (
    <div className="menu">
      <List>
        <ListItem
          key="Home"
        >
           <Link style={{paddingLeft: '0px'}} to="/" component={RouterLink} onClick={handleMenuClick} className={classes.listLink}>Home</Link>
        </ListItem>
        <ListItem key="My Practice">
          <ListItemText primary="My Practice" />
        </ListItem>
        {myPracticeMenu.map((content, index) => (
          <ListItem
            key={content[1]}
            className={classes.nested}
          >
            <Link to={`/${content[1]}`} component={RouterLink} onClick={handleMenuClick} className={classes.listLink}>{content[0]}</Link>
          </ListItem>
        ))}
        <ListItem key="About Me">
          <ListItemText primary="About Me" />
        </ListItem>
        {aboutMeMenu.map((content, index) => (
          <ListItem
            key={content[1]}
            className={classes.nested}
          >
            <Link to={`/${content[1]}`} component={RouterLink} onClick={handleMenuClick} className={classes.listLink}>{content[0]}</Link>
          </ListItem>
        ))}
        <ListItem key="Chinese Medicine">
          <ListItemText primary="Chinese Medicine"  />
        </ListItem>
        {chineseMedicineMenu.map((content, index) => (
          <ListItem
            key={content[1]}
            className={classes.nested}
          >
            <Link to={`/${content[1]}`} component={RouterLink} onClick={handleMenuClick} className={classes.listLink}>{content[0]}</Link>
          </ListItem>
        ))}
      </List>
      
      
    </div>
  )

  const container = window !== undefined ? () => window().document.body : undefined

  return (
    <Router>
      <Analytics id="G-T9EG1JRBFR">
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} elevation={0}>
        <Toolbar >
          <IconButton            
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Header />
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="left navigation">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            elevation={0}
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={menuOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/directions">
          <Directions />
        </Route>
        <Route path="/your_first_visit">
          <YourFirstVisit />
        </Route>
        <Route path="/fees_billing">
          <FeesAndBilling />
        </Route>
        <Route path="/our_clinic">
          <OurClinic />
        </Route>
        <Route path="/patient_forms">
          <PatientForms/>
        </Route>
        <Route path="/testimonials">
          <Testimonials/>
        </Route>
        <Route path="/privacy">
          <Privacy/>
        </Route>
        <Route path="/my_background">
          <MyBackground/>
        </Route>
        <Route path="/why_i_practice">
          <WhyPractice/>
        </Route>
        <Route path="/my_logo">
          <MyLogo/>
        </Route>
        <Route path="/all_about_tcm">
          <AllAboutTCM/>
        </Route>
        <Route path="/acupuncture">
          <Acupuncture/>
        </Route>
        <Route path="/herbal_medicine">
          <HerbalMedicine/>
        </Route>
        <Route path="/what_is_qi">
          <WhatIsQi/>
        </Route>
        <Route path="/qi_sensation">
          <QiSensation/>
        </Route>
        <Route path="/meridians">
          <Meridians/>
        </Route>
        <Route path="/organs">
          <Organs/>
        </Route>
        <Route path="/yin_yang">
          <YinYang/>
        </Route>
        <Route path="/links">
          <Links/>
        </Route>
        <Route path="/static/PatientForms.pdf" onEnter={reload}/>
        <Route path="*">
          <Home />
        </Route>
      </Switch>
      </main>
    </div>
    </Analytics>
    </Router>
  )
}

export default ResponsiveDrawer
