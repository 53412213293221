import React from 'react'
import Typography from '@material-ui/core/Typography'

export default function QiSensation() {
  return (
    <React.Fragment>
      <Typography  variant="h5"  align="left" >
        The Qi Sensation
      </Typography>
      <Typography paragraph align="left">
        When acupuncture needles are first inserted into the body, most people feel very little, if
        any, sensation. It is not until a needle is inserted to its proper depth and an appropriate
        degree of stimulation is applied that what we call the "Qi sensation" is achieved.
        "Stimulation" can be a matter of simply twirling the needle in place, giving it a mild or
        moderate lift and thrust, applying heat, or even hooking the needles up to a mild electric
        current (electroacupuncture), but the end result is the same: the Qi sensation.
      </Typography>
      <Typography paragraph align="left">
        The arrival of Qi at an acupoint has been described in many ways&#8212;a dull ache,
        "electric" tingling, heaviness, numbness, distention&#8212;and it often feels different
        depending where on the body the needle is placed, the nature of the disorder being treated,
        and the overall health of the patient. It may radiate up or down a limb or the trunk, or may
        be localized at the site of insertion. Sometimes the patient even feels the sensation in
        another location entirely from the one being needled. The Qi knows where it's needed!
      </Typography>
      <Typography paragraph align="left">
        Qi may come on quickly, sometimes surprisingly so, or it may take a moment's stimulation to
        draw it to the point. Sometimes the acupuncturist feels the arrival of Qi before the patient
        does, through contact with the needle, while at other times the patient feels it first and
        alerts the acupuncturist verbally. Either way, the goal is to bring the Qi to the needle, so
        that the effects of the acupoint (each one has its own unique effects) can be realized and
        the benefits retained. Without the arrival of Qi, the effectiveness of the treatment is
        significantly lessened, so a good acupuncturist strives to achieve the Qi sensation with
        every point used.
      </Typography>
    </React.Fragment>
  )
}
